import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cls from "classnames";
import style from "./style.module.scss";
import Slider, { Controls } from "../Slider";
import { callback, getAssetsIcon } from "../../utilities";
import { Carousel as AntdCarousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { defaultImageAssets } from "./constants";
import { TemplateModel, TutorialModel } from "../../types";
import { useSignal } from "@preact/signals-react";

export interface CarouselProps {
  templates: TemplateModel[];
  tutorials: TutorialModel[];
  onTutorialClick?: (tutorial: TutorialModel) => void;
  onTemplateClick?: (template: TemplateModel) => void;
}

export interface Pagination {
  page: number;
  offset: number;
  limit: number;
}

const Carousel: FC<CarouselProps> = ({
  templates,
  tutorials,
  onTutorialClick = callback,
  onTemplateClick = callback,
}) => {
  const sliderControlsRef = useRef<HTMLDivElement | null>(null);
  const imageCarouselRef = useRef<CarouselRef | null>(null);
  const contentCarouselRef = useRef<CarouselRef | null>(null);
  const contentSliderRef = useRef<HTMLDivElement | null>(null);
  const imgSliderRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);
  const [positions, setPositions] = useState<number[]>([]);
  const contentSliderWidth = useSignal(0);
  const imgSliderWidth = useSignal(0);

  const temps = useMemo(() => {
    return templates.filter(t => {
      return tutorials.find(v => v.project_id === t.project_id);
    });
  }, [templates, tutorials]);

  useEffect(() => {
    setImages(defaultImageAssets);
    const newPositions = templates.map((_, index) => index);
    setPositions(newPositions);
  }, [templates]);
  function disableClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }
  const handleTemplateClick = useCallback(
    // (template: TemplateModel) => () => {
    //   onTemplateClick(template);
    // },
    // [onTemplateClick]
    async (template: TemplateModel) => {
      document.addEventListener("click", disableClick, true);
      try {
        await onTemplateClick(template);
      } catch (error) {
        console.error("handleTemplateClick", error);
      }
      setTimeout(() => {
        document.removeEventListener("click", disableClick, true);
      }, 2000);
    },
    [onTemplateClick]
  );

  const handleTutorialClick = useCallback(
    (template: TemplateModel) => () => {
      const tutorial = tutorials.find(v => {
        const areProjectIdsEqual = v.project_id === template.project_id;
        return areProjectIdsEqual;
      });
      if (tutorial == null) {
        console.error("Carousel: the tutorial is not found");
        return;
      }
      onTutorialClick(tutorial);
    },
    [onTutorialClick, tutorials]
  );

  const handlePositionClick = useCallback(
    (newPosition: number, control: Controls) => {
      if (!imageCarouselRef.current || !contentCarouselRef.current || !sliderControlsRef.current) {
        return;
      }
      switch (control) {
        case Controls.ArrowLeft:
          if (newPosition < 0) {
            newPosition = positions.length - 1;
            setPosition(newPosition);
            imageCarouselRef.current.goTo(newPosition);
            contentCarouselRef.current.goTo(newPosition);
            sliderControlsRef.current.scrollTo({
              left: 900,
              behavior: "smooth",
            });
            return;
          }
          setPosition(newPosition);
          imageCarouselRef.current.goTo(newPosition);
          contentCarouselRef.current.goTo(newPosition);
          if (newPosition < 7 && newPosition >= 0) {
            sliderControlsRef.current.scrollTo({
              left: 0,
              behavior: "smooth",
            });
          }
          if (newPosition < 14 && newPosition >= 7) {
            sliderControlsRef.current.scrollTo({
              left: 300,
              behavior: "smooth",
            });
          }
          if (newPosition < 21 && newPosition >= 14) {
            sliderControlsRef.current.scrollTo({
              left: 600,
              behavior: "smooth",
            });
          }
          break;
        case Controls.ArrowRight:
          if (newPosition >= templates.length) {
            setPosition(0);
            imageCarouselRef.current.goTo(0);
            contentCarouselRef.current.goTo(0);
            sliderControlsRef.current.scrollTo({
              left: 0,
              behavior: "smooth",
            });
            return;
          }
          setPosition(newPosition);
          imageCarouselRef.current.goTo(newPosition);
          contentCarouselRef.current.goTo(newPosition);
          if (newPosition >= 7) {
            sliderControlsRef.current.scrollTo({
              left: 300,
              behavior: "smooth",
            });
          }
          if (newPosition >= 14) {
            sliderControlsRef.current.scrollTo({
              left: 600,
              behavior: "smooth",
            });
          }
          if (newPosition >= 21) {
            sliderControlsRef.current.scrollTo({
              left: 900,
              behavior: "smooth",
            });
          }
          break;
        case Controls.Control:
        default:
          if (newPosition >= templates.length) {
            setPosition(0);
            imageCarouselRef.current.goTo(0);
            contentCarouselRef.current.goTo(0);
            sliderControlsRef.current.scrollTo({
              left: 0,
              behavior: "smooth",
            });
            return;
          }
          if (newPosition >= 7) {
            sliderControlsRef.current.scrollTo({
              left: 300,
              behavior: "smooth",
            });
          }
          if (newPosition >= 14) {
            sliderControlsRef.current.scrollTo({
              left: 600,
              behavior: "smooth",
            });
          }
          if (newPosition >= 21) {
            sliderControlsRef.current.scrollTo({
              left: 900,
              behavior: "smooth",
            });
          }
          setPosition(newPosition);
          imageCarouselRef.current.goTo(newPosition);
          contentCarouselRef.current.goTo(newPosition);
          break;
      }
    },
    [positions.length, templates.length]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (contentSliderRef.current) {
        // 在容器大小变化时强制更新 contentCarousel
        contentSliderWidth.value = contentSliderRef.current.clientWidth;
      }
      if (imgSliderRef.current) {
        // 在容器大小变化时强制更新 imageCarousel
        imgSliderWidth.value = imgSliderRef.current.clientWidth;
      }
    });

    if (contentSliderRef.current) {
      resizeObserver.observe(contentSliderRef.current);
    }

    if (imgSliderRef.current) {
      resizeObserver.observe(imgSliderRef.current);
    }

    // 清理观察器
    return () => {
      if (contentSliderRef.current) {
        resizeObserver.unobserve(contentSliderRef.current);
      }
      if (imgSliderRef.current) {
        resizeObserver.unobserve(imgSliderRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      handlePositionClick(position + 1, Controls.Control);
    }, 3000);

    return () => clearInterval(timer);
  }, [position, handlePositionClick, contentSliderWidth.value]);

  return (
    <div className={style.sliderContainer}>
      <div className={style.sliderBox}>
        <div ref={imgSliderRef} className={cls(style.antdCarouselInit, style.sliderImgsBox)}>
          <AntdCarousel ref={imageCarouselRef} initialSlide={position} dots={false} key={`img${imgSliderWidth.value}`}>
            {temps.map((template, index) => (
              <div className={style.imgsCarouselBox} key={index}>
                <img className={style.imgsCarouselBg} src={images[index % 10]} alt="Create Your Business Plan" />
                <p className={style.imgsCarouselTitle}>{template.title}</p>
              </div>
            ))}
          </AntdCarousel>
        </div>
        <div
          ref={contentSliderRef}
          className={cls(style.antdCarouselInit, style.sliderTxtsBox, style.sliderTxtsSafariBox)}
        >
          <AntdCarousel
            ref={contentCarouselRef}
            initialSlide={position}
            dots={false}
            key={`content${contentSliderWidth.value}`}
          >
            {temps.map((template, index) => (
              <div className={style.txtsCarouselBox} key={index}>
                <div className={style.txtCarouselDescriptionBox}>
                  <section>
                    <h5 className={style.txtCarouselTitle}>{template.title}</h5>
                    <p className={style.txtCarouselDescription}>{template.description}</p>
                  </section>

                  <div className={style.txtCarouselActionBox}>
                    <button className={cls(style.button, style.button_black)} onClick={handleTutorialClick(template)}>
                      <div className={style.button__icon}>
                        <img className={style.icon} src={getAssetsIcon("play.svg")} alt="" />
                      </div>
                      <div className={style.button__text}>
                        <span className={style.text_buttonBlack}>Watch Tutorial</span>
                      </div>
                    </button>

                    <button
                      className={cls(style.button, style.button_none)}
                      onClick={() => handleTemplateClick(template)}
                    >
                      <div className={style.button__icon}>
                        <img className={style.icon} src={getAssetsIcon("plus-blue.svg")} alt="" />
                      </div>
                      <div className={style.button__text}>
                        <span className={style.text_buttonNone}>Get this template</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </AntdCarousel>
          <footer className={cls(style.isShowSliderControler, style.sliderContentControlerBox)}>
            <Slider
              positions={positions}
              position={position}
              controlsRef={sliderControlsRef}
              onPositionClick={handlePositionClick}
            />
          </footer>
        </div>
      </div>
      <footer className={cls(style.isShowLongSliderControler, style.sliderContentControlerBox)}>
        <Slider
          positions={positions}
          position={position}
          controlsRef={sliderControlsRef}
          onPositionClick={handlePositionClick}
        />
      </footer>
    </div>
    // <div className={style.slider}>
    //   <div className={style.slider__image}>
    //     <AntdCarousel className={style.slider__image} dots={false} ref={imageCarouselRef}>
    //       {temps.map((template, index) => (
    //         <div className={style.sliderImage} key={index}>
    //           <div className={style.sliderImage__image}>
    //             <img
    //               className={cls(style.image, style.image_sliderImage)}
    //               src={images[index % 10]}
    //               alt="Create Your Business Plan"
    //             />
    //           </div>
    //           <div className={style.sliderImage__text}>{template.title}</div>
    //         </div>
    //       ))}
    //     </AntdCarousel>
    //   </div>
    //   <div className={style.slider__content}>
    //     <div className={style.sliderContent}>
    //       <div className={style.sliderContent__carousel}>
    //         <AntdCarousel className={style.carousel} ref={contentCarouselRef} dots={false}>
    //           {temps.map((template, index) => (
    //             <div className={style.sliderContentItem} key={index}>
    //               <div className={style.sliderContentItem__description}>
    //                 <span className={cls(style.text, style.text_title)}>{template.title}</span>
    //                 <span className={cls(style.text, style.text_description)}>{template.description}</span>
    //               </div>
    //               <div className={style.sliderContentItem__actions}>
    //                 <div>
    //                   <button className={cls(style.button, style.button_black)} onClick={handleTutorialClick(template)}>
    //                     <div className={style.button__icon}>
    //                       <img className={style.icon} src={getAssetsIcon("play.svg")} alt="" />
    //                     </div>
    //                     <div className={style.button__text}>
    //                       <span className={style.text_buttonBlack}>Watch Tutorial</span>
    //                     </div>
    //                   </button>
    //                 </div>
    //                 <div>
    //                   <button className={cls(style.button, style.button_none)} onClick={handleTemplateClick(template)}>
    //                     <div className={style.button__icon}>
    //                       <img className={style.icon} src={getAssetsIcon("plus-blue.svg")} alt="" />
    //                     </div>
    //                     <div className={style.button__text}>
    //                       <span className={style.text_buttonNone}>Get this template</span>
    //                     </div>
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           ))}
    //         </AntdCarousel>
    //       </div>
    //       <div className={style.sliderContent__control}>
    //         <Slider
    //           positions={positions}
    //           position={position}
    //           controlsRef={sliderControlsRef}
    //           onPositionClick={handlePositionClick}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Carousel;
