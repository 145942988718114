import { getIt } from "@uikit/getIt";
import React, { useEffect } from "react";
import { matchMobile } from "@uikit/func";
import { Cot } from "@uiview/pages/mobile/COT/Cot";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { LottieAnimation } from "@views/components/LottieAnimation";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { DashboardLayout } from "@uiview/layout/DashboardLayout";
import { useModal } from "@uiview/views/AModal/useModal";
import { AModalContext } from "@uikit/context/AModalContext/AModalContext";
import { FotNavigateUtil } from "@uikit/util/FotNavigateUtil";

export type EditorProps = {
  page?: string;
  share?: boolean;
};

export function Editor(props: EditorProps): React.ReactNode {
  const homeStore = getIt(HomeStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const homeStoreState = useStore(homeStore).value;
  const feature_tags = homeStoreState.featureTags;
  const showModal = useModal();
  const location = useLocation();
  const isMobile = matchMobile();
  const urlParam = useParams();

  // update showNavPageStore default state
  useEffect(() => {
    showNavPageStore.setDefaultState(isEmpty(urlParam.attrIndexer));
  }, []);

  if (isMobile && feature_tags.disableMobilePage === false && props.share !== true) {
    return <Cot />;
  }

  /**
   * 这样做的目的是为了让其他 outlet 可自定义渲染内容，否则不能脱离 editor
   *
   * 目前需要脱离的是 /editor/function-normal/:id 页面
   *
   * 原因是: homepage 和 canvaspage 中, 分别同时存在 canvas, 普通函数是在 canvas 中修改的,
   *  如果在 canvaspage 中在打开一个普通函数修改, 先正式环境也没有这样的功能, 所以它不应该算在原来那种渲染模式下
   */
  const layout = !FotNavigateUtil.isNotEditorLayout(location);

  return (
    <AModalContext.Provider value={{ showModal }}>
      {/* STUD-2195: 预加载一次，为了修复cot unsupport弹窗和onboarding弹窗，首次显示时动画没有播放 */}
      <LottieAnimation loader={null} />
      {layout ? (
        <>
          <DashboardLayout />
          <Outlet />
        </>
      ) : (
        <Outlet />
      )}
    </AModalContext.Provider>
  );
}
