import "reflect-metadata";
import "imagica-corekit/dist/base/cutil/Extensions";
import "@uikit/func";
import { UpgradeSuccess } from "@uiview/pages/upgradeSuccess/UpgradeSuccess";
import { PlansList } from "@uiview/pages/PlansList";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { message } from "antd";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import UI from "./views/UI";
import Studio from "./views/Studio";
import BuildLayerView from "./views/BuildLayerView";
import SharedCanvasView from "./views/thinking-layout-editor/SharedCanvasView";
import { ErrorBoundary } from "./views/ErrorBoundary";
import store from "./store";
import { fotActions } from "./store/fot";
import { WithRouterLogin as Login } from "@views/login/Login";
import DomainBasedPermission from "./views/DomainBasedPermission";
import NotFound from "./views/NotFound";
import ActivateError from "./views/ActivateError";
import DocumentMeta from "./views/components/DocumentMeta";
import { ExamplePage } from "./uiview/pages/example/ExamplePage";
import { RestClientPageBloc } from "./uiview/pages/example/RestClientPageBloc";
import { RestClientPage } from "./uiview/pages/example/RestClientPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import LoadingOutline from "./views/components/LoadingOutline";
import { SubscriptionPage } from "@uiview/pages/profile/subscription";
import { getIt } from "@uikit/getIt";
import { useEventbus } from "imagica-uikit/dist/hooks/useEventbus";
import { UnauthorizedMsg } from "imagica-corekit/dist/base/msg/UnauthorizedMsg";
import { ProfilePage } from "@uiview/pages/profile/ProfilePage";
import { CustomApiPage } from "@uiview/pages/customApi/CustomApiPage";
import { AccountPage } from "@uiview/pages/profile/account/AccountPage";
import PasswordPage from "@uiview/pages/profile/password/PasswordPage";
import { Editor } from "@uiview/pages/editor/Editor";
import { HomePluginStore } from "imagica-corekit/dist/base/store/HomePluginStore";
import { useStore as useImagincStore } from "imagica-uikit/dist/hooks/useStore";
import { AccountMobile } from "@uiview/views/Mobile/Account/AccountMobile";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";
import { FotProcessStore } from "@uikit/store/FotProcessStore";
import { PlanMobile } from "@uiview/views/Mobile/Plan";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { allPreDefinedFunctions } from "@views/thinking-layout-editor/nodeTypeComponents/nodeTypeDispose";
import { CreatorFunctionNormalPage } from "@uiview/pages/creatorFunctionNormalPage/CreatorFunctionNormalPage";
import { FotNavigateRoutes } from "@uikit/cases/fotNavigate/FotNavigatePage";
import { CreatorFunctionJSPage } from "@uiview/pages/creatorFunctionJSPage/CreatorFunctionJSPage";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(document.getElementById("root"));

const DeniedPage = () => {
  return (
    <div className="permission-denied">
      <p>Permission Denied</p>
    </div>
  );
};

const Root = () => {
  const homePluginStore = getIt(HomePluginStore);
  const fotProcessStore = getIt(FotProcessStore);
  const homePluginState = useImagincStore(homePluginStore).value;
  const fotProcessState = useImagincStore(fotProcessStore).value;
  const location = useLocation();

  message.config({
    maxCount: 1,
  });
  window.onbeforeunload = function () {
    localStorage.removeItem("studioMode");
    localStorage.removeItem("testMode");
  };
  const isMobile = window.navigator.userAgent.includes("Mobile");
  const dispatch = useDispatch();
  const fotProcessManager = FotProcessManager.use();
  const isLogin = useSelector(state => state.fot.isLogin);
  const showShareSignin = useSelector(state => state.fot.showShareSignin);

  const setIsLogin = useCallback(
    val => {
      dispatch(fotActions.setIsLogin(val));
    },
    [dispatch]
  );

  const hideStudioBtnPaths = ["/layer", "/editor"];

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  useEffect(() => {
    fotProcessManager.init();
    // FIXME: projectFunctionStore 也使用了 allPreDefinedFunctions ui 数据，所以在根节点提前设置
    getIt(SaveTemplateService).setDependencies({ allPreDefinedFunctions });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Anton: useEventbus throws an error */
  useEventbus(UnauthorizedMsg, () => {
    console.log("--------------------------- UnauthorizedMsg");
    fotProcessManager.logout();
  });

  return (
    <div>
      <DocumentMeta isLogin={isLogin} isShare={homePluginState.isShare} shareProjectName={selectedTemplate.name} />
      {!isMobile && !hideStudioBtnPaths.some(x => location.pathname.includes(x)) ? <DomainBasedPermission /> : null}
      {fotProcessState.isInitLoading ? (
        <LoadingOutline />
      ) : !isLogin && !showShareSignin ? (
        <>
          <Routes>
            <Route path="/signin" element={<Login setIsLogin={setIsLogin} isSignUp={false} />} />
            <Route path="/signup" element={<Login setIsLogin={setIsLogin} isSignUp={true} />} />
            <Route path="*" element={<Login setIsLogin={setIsLogin} isSignUp={false} />} />
          </Routes>
        </>
      ) : (
        <div className="route-box">
          <Routes>
            <Route path="/ExamplePage" element={<ExamplePage></ExamplePage>}></Route>
            <Route
              path="/RestClientPage"
              element={<RestClientPage bloc={new RestClientPageBloc()}></RestClientPage>}
            ></Route>
            <Route path="/editor" element={<Editor share={homePluginState.isShare} />}>
              {subChildrenRoute(true)}
            </Route>
            <Route path={"/editor/:attrIndexer"} element={<Editor />}>
              {subChildrenRoute()}
            </Route>
            <Route path="/editor/shared_intelligent_app/:projectId" element={<Editor share={true} />}></Route>
            <Route path="/editor/shared_canvas" element={<SharedCanvasView />}></Route>
            <Route path={"/editor/error"} element={<ActivateError />} />
            <Route path={"/editor/denied-tip"} element={<DeniedPage />} />

            <Route
              path="/layer"
              element={
                <DomainBasedPermission>
                  <BuildLayerView clickLogOut={fotProcessManager.logout.bind(fotProcessManager)} />
                </DomainBasedPermission>
              }
            ></Route>
            <Route
              path="/studio"
              element={
                <DomainBasedPermission>
                  <ErrorBoundary>
                    {" "}
                    <Studio />{" "}
                  </ErrorBoundary>
                </DomainBasedPermission>
              }
            ></Route>
            <Route
              path="/"
              element={
                <DomainBasedPermission>
                  <UI UIMode="productionMode" clickLogOut={fotProcessManager.logout.bind(fotProcessManager)} />
                </DomainBasedPermission>
              }
            ></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

root.render(
  <GoogleOAuthProvider clientId={settings.googleOauthClientId}>
    <Provider store={store}>
      <Router>
        <Root />
      </Router>
    </Provider>
  </GoogleOAuthProvider>
);

const profileRouteConfig = () => {
  const isMobile = window.navigator.userAgent.includes("Mobile");
  const homeStore = getIt(HomeStore);
  const featureTags = homeStore.state.featureTags;
  const shouldShowMobile = isMobile && featureTags.disableMobilePage;

  return (
    <>
      <Route path="profile" element={<ProfilePage />}>
        <Route path="account" element={shouldShowMobile ? <AccountMobile /> : <AccountPage />}></Route>
        <Route path="password" element={<PasswordPage />}></Route>
        <Route path={shouldShowMobile ? "account" : "subscription"} element={<SubscriptionPage />}>
          <Route path="upgrade_plans" element={shouldShowMobile ? <PlanMobile /> : <PlansList />}></Route>
          <Route path="upgrade_success" element={<UpgradeSuccess />}></Route>
        </Route>
      </Route>
      <Route path="upgrade_plans" element={shouldShowMobile ? <PlanMobile /> : <PlansList />}></Route>
      <Route path="upgrade_success" element={<UpgradeSuccess />}></Route>
      <Route path={FotNavigateRoutes.FUNCTION_API_ROUTE} element={<CustomApiPage />}></Route>
    </>
  );
};

const subChildrenRoute = editorRoot => {
  return (
    <>
      {profileRouteConfig()}
      {/* normal 不能在 cavnas 中编辑, 路由还无法支持到 editor/:attrindex 下面,所以只能是在 /editor 下面 */}
      {editorRoot && (
        <Route path={FotNavigateRoutes.FUNCTION_NORMAL_ROUTE} element={<CreatorFunctionNormalPage />}>
          {profileRouteConfig()}
        </Route>
      )}
      <Route path={FotNavigateRoutes.FUNCTION_JS_ROUTE} element={<CreatorFunctionJSPage />} />
    </>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
