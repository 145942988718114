import { ApiStatuses, FunctionModel } from "@views/thinking-layout-editor/types";
import { RelationSetDest } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { PersonalFunctionUtil } from "imagica-corekit/dist/base/util/PersonalFunctionUtil";
import { SaveTemplateService } from "@uikit/service/SaveTemplateService";
import { ProjectFunctionUtil } from "@uikit/util/ProjectFunctionUtil";

export class ProjectFunctionStoreState {
  functions = [] as FunctionModel[];
  functionLoading: ApiStatuses = ApiStatuses.Idle;

  copyWith(param: Partial<Omit<ProjectFunctionStoreState, "copyWith">>): ProjectFunctionStoreState {
    const newState = new ProjectFunctionStoreState();
    newState.functions = param.functions ?? this.functions;
    newState.functionLoading = param.functionLoading ?? this.functionLoading;
    return newState;
  }
}

export class ProjectFunctionStore extends StoreBase<ProjectFunctionStoreState> {
  constructor(public personalFunctionStore: PersonalFunctionStore, private saveTemplateService: SaveTemplateService) {
    super(() => new ProjectFunctionStoreState());

    personalFunctionStore.on(functions => {
      const newFunctions = functions.map(item =>
        ProjectFunctionUtil.relationSetDestToFunctionModel(
          item,
          item.originElement as RelationSetDest,
          saveTemplateService.dependencies.allPreDefinedFunctions
        )
      );
      this.emit(this.state.copyWith({ functions: newFunctions }));
    }, true);
  }

  async init(): Promise<FunctionModel[]> {
    this.emit(this.state.copyWith({ functionLoading: ApiStatuses.Pending }));
    const allPreDefinedFunctions = this.saveTemplateService.dependencies.allPreDefinedFunctions;
    return this.personalFunctionStore
      .get()
      .then(functions => {
        const newFunctions = functions.map(item =>
          ProjectFunctionUtil.relationSetDestToFunctionModel(
            item,
            item.originElement as RelationSetDest,
            allPreDefinedFunctions
          )
        );
        this.emit(this.state.copyWith({ functions: newFunctions }));

        return newFunctions;
      })
      .finally(() => {
        this.emit(this.state.copyWith({ functionLoading: ApiStatuses.Fulfilled }));
      });
  }

  deleteFunctionById(id: string): void {
    const newPersonalFunctions = this.personalFunctionStore.state.filter(item => item.destId !== id);
    this.personalFunctionStore.emit(newPersonalFunctions);
  }

  updateFunctionById(id: string, funcData: any): void {
    const tempFunctions = [...this.personalFunctionStore.state];
    const currPersonalFunctionIdx = tempFunctions.findIndex(item => item.destId === id);
    if (currPersonalFunctionIdx !== -1) {
      const newPersonalFunction = {
        ...tempFunctions[currPersonalFunctionIdx],
        ...funcData,
      };
      tempFunctions.splice(currPersonalFunctionIdx, 1, newPersonalFunction);
      this.personalFunctionStore.emit(tempFunctions);
    }
  }

  copyFunction(func: FunctionModel): RelationSetDest | undefined {
    const relationSetDest = JsonUtil.toModelFromType(RelationSetDest, func);
    if (!relationSetDest) {
      return;
    }

    const attri = PersonalFunctionUtil.relationSetDestToRelationSetDestAttribute(relationSetDest);
    if (!attri) {
      return;
    }

    this.personalFunctionStore.emit([attri, ...this.personalFunctionStore.state]);

    return relationSetDest;
  }

  /**
   * 获取普通函数
   * @param functionId
   * @returns
   */
  getFunctionNormal(functionId: string): FunctionModel | undefined {
    return this.state.functions.find(func => func.id === functionId && !(func as any).type);
  }

  getFunctionJS(functionId: string): FunctionModel | undefined {
    return this.state.functions.find(func => func.id === functionId && (func as any).type === "CustomCode");
  }
}
