import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { FotNavigatePage, FotNavigateRoutes } from "./FotNavigatePage";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { FunctionModel } from "@views/thinking-layout-editor/types";
import { cloneDeep, isEmpty } from "lodash";
import func from "@uikit/func";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { StoreBase } from "imagica-corekit/dist/base/cutil/StoreBase";
import { GenUtil } from "imagica-corekit/dist/base/cutil/GenUtil";

export type FunctionJsPageData = {
  open?: boolean;
  loading?: boolean;
  edgeId?: string;
  sourceNodeId?: string;
  targetNodeId?: string;
  state?: "modify" | "new";
  select?: FunctionModel;
  parameter?: {
    name?: string;
    description?: string;
  };
  // FIXME: 原来 homecollection view 点击时设置了 oncancel
  function?: "clickRun" | "onCancel" | "handleOk" | "";
  [key: string]: any;
};

/**
 * 该数据是 redux 中 jsfunction 原始初始值
 */
const defaultFunctionData = {
  open: false,
  loading: false,
  edgeId: "",
  sourceNodeId: "",
  targetNodeId: "",
  function: "",
  select: {},
  parameter: {},
} as FunctionJsPageData;

const CreatedRouteID = "Untitled";

class FotFunctionJSPageState {
  success: boolean = false;

  copyWith(params: { success?: boolean }): FotFunctionJSPageState {
    return GenUtil.copyWith(new FotFunctionJSPageState(), this, params);
  }
}

/**
 * 该 class 为 JS函数页面
 *
 * @implements FotNavigatePage
 * @extends StoreBase
 */
export class FotFunctionJSPage
  extends StoreBase<FotFunctionJSPageState>
  implements FotNavigatePage<FunctionJsPageData>
{
  readonly route = FotNavigateRoutes.FUNCTION_JS_ROUTE;

  constructor(
    readonly controller: FotNavigateController,
    private projectFunctionStore: ProjectFunctionStore,
    private fotReduxStore: FotReduxStore,
    private uiFunctionService: UiFunctionService
  ) {
    super(() => new FotFunctionJSPageState());
  }

  changeSuccess(success: boolean): void {
    if (this.state.success !== success) {
      this.emit(this.state.copyWith({ success }));
    }
  }

  init(functionId?: string): void {
    if (!functionId) {
      return;
    }

    if (this.isCreateRoute(functionId)) {
      this.changeSuccess(true);
      return;
    }

    this.changeSuccess(false);

    // 1. 首先保证用户function 已经设置完成
    this.uiFunctionService.awaitProjectFunctions().then(() => {
      // 已经设置了 function 数据
      const JSFunctionData = this.getPageData();
      if (!isEmpty(JSFunctionData) && JSFunctionData.select?.id === functionId) {
        this.changeSuccess(true);
        return;
      }

      // type 需要为 CustomCode
      const target = this.projectFunctionStore.getFunctionJS(functionId);
      if (!target) {
        console.error("Page Not Found");
        this.controller.openCreatorHomePage();
        this.resetPageData();
        return;
      }

      // 当前已经在 function js 页, 则直接更新数据无需跳转
      this.open({
        select: target,
        parameter: {
          name: target.value,
          description: target.description,
        },
      });
      this.changeSuccess(!isEmpty(target));
    });
  }

  /**
   * @override
   */
  open(data: FunctionJsPageData): void {
    this.changePageData({
      open: true,
      edgeId: "",
      sourceNodeId: "",
      targetNodeId: "",
      state: "modify",
      ...data,
    });

    if (!data.select?.id) {
      console.warn("Open FunctionJSPage id not found");
      return;
    }

    this.controller.gotoRoute(this.route, { id: data.select?.id });
  }

  /**
   * @override
   */
  close(destroy = false): void {
    this.resetPageData();

    // 是否是离开页面,或者是销毁页面, 由于不能控制浏览器返回按钮, 现在是在组件销毁是会被执行
    // 主要是为了当点击浏览器回退时状态不正确
    if (destroy) {
      // TODO: 这里应该模仿点击logo的行为逻辑
      return;
    }

    this.gobackRoute();
  }

  /**
   * @override
   * @param pageData
   */
  changePageData(pageData: FunctionJsPageData): void {
    this.fotReduxStore.setCreateJSFuncData(pageData);
  }

  resetPageData(): void {
    this.changePageData(cloneDeep(defaultFunctionData));
  }

  openCreate(value: FunctionJsPageData): void {
    this.changePageData({ ...value, state: "new" });
    this.controller.gotoRoute(this.route, { id: CreatedRouteID });
  }

  getPageData(): FunctionJsPageData {
    return this.fotReduxStore.getState().editor.createJSFuncData as FunctionJsPageData;
  }

  gobackRoute(): void {
    // 将类似 "/editor/61c2b6b3-6fb8-4b6d-bd64-d90990f87705/js-function/306972" 地址变成 "/editor/61c2b6b3-6fb8-4b6d-bd64-d90990f87705"
    // 将类似 "/editor/js-function/306972" 地址变成 "/editor"
    // 正则匹配 "/js-function/306972" 并移除它(原则上来说直接history.goback 最好)
    const newPathname = this.controller.location.pathname.split(this.route.replace(":id", ""))[0].slice(0, -1);
    this.controller.gotoRoute(newPathname);
  }

  /**
   * 当 pageData 中的 select 发生改变时触发,现在是在组件中用 useEffect 触发
   * @param pageData
   * @param uicallback
   * @returns
   */
  onChangeSelect(
    pageData: FunctionJsPageData,
    uicallback?: (codeData: Required<FunctionModel["codeData"]>) => void
  ): void {
    if (func.isEmpty(pageData.select)) {
      return;
    }
    // copy function在 imageGenSelectOptions 中
    const imageGenSelectOptions = this.fotReduxStore.getState().fot.imageGenSelectOptions;

    let currFuncData = imageGenSelectOptions.find(x => x.label === pageData.select?.label) as FunctionModel | undefined;
    // 如果没有找到则直接使用当前函数上的数据, 当 ProjectFunction 和 UiFunctionService 合并后需要处理
    if (!currFuncData) {
      currFuncData = pageData.select;
    }

    uicallback?.({
      htmlStr: currFuncData?.codeData?.htmlStr || "",
      cssStr: currFuncData?.codeData?.cssStr || "",
      jsStr: currFuncData?.codeData?.jsStr || "",
    });
  }

  isCreateRoute(id: string): boolean {
    return this.getPageData().state === "new" || id === CreatedRouteID;
  }
}
