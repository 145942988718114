import { editorActions } from "@store/editor";
import { fotActions } from "@store/fot";
import { studioActions } from "@store/studio";
import { FunctionJsPageData } from "@uikit/cases/fotNavigate/FotFunctionJSPage";

export type FotReduxStoreType = typeof import("@store/index").default;

/**
 * 该 class 是对 redux store 的一个封装，避免同样的方法被声明多次
 */
export class FotReduxStore {
  constructor(public reduxStore: FotReduxStoreType) {}

  getState(): ReturnType<FotReduxStoreType["getState"]> {
    return this.reduxStore.getState();
  }

  setSelectedTemplate(val: PreviewApp.ProjectType): void {
    this.reduxStore.dispatch(studioActions.setSelectedTemplate(val));
  }

  setMeLoading(val: any): void {
    this.reduxStore.dispatch(editorActions.setMeLoading(val));
  }

  setMe(val: any): void {
    this.reduxStore.dispatch(editorActions.setMe(val));
  }

  setIsLogin(val: boolean): void {
    this.reduxStore.dispatch(fotActions.setIsLogin(val));
  }

  setLoginMethod(val: any): void {
    this.reduxStore.dispatch(editorActions.setLoginMethod(val));
  }

  setImageGenSelectOptions(functions: any[]): void {
    this.reduxStore.dispatch(fotActions.setImageGenSelectOptions(functions));
  }

  setShowShareSignin(val: boolean): void {
    this.reduxStore.dispatch(fotActions.setShowShareSignin(val));
  }

  setDisableAddPreview(val: any): void {
    this.reduxStore.dispatch(fotActions.setDisableAddPreview(val));
  }

  setVariableList(val: any): void {
    this.reduxStore.dispatch(fotActions.setVariableList(val));
  }
  setImageScrollDireaction(val: any): void {
    this.reduxStore.dispatch(fotActions.setImageScrollDireaction(val));
  }

  setCheckEdgeArr(val: string[]): void {
    this.reduxStore.dispatch(fotActions.setCheckEdgeArr(val));
  }

  setHoverEdgeData(val: any): void {
    this.reduxStore.dispatch(editorActions.setHoverEdgeData(val));
  }

  setHasSelectedArea(val: boolean): void {
    this.reduxStore.dispatch(fotActions.setHasSelectedArea(val));
  }

  setCheckNodeArr(val: any[]): void {
    this.reduxStore.dispatch(fotActions.setCheckNodeArr(val));
  }

  setSameTargetEdgeIds(val: any[]): void {
    this.reduxStore.dispatch(editorActions.setSameTargetEdgeIds(val));
  }

  setMultiSameTargetEdgeIds(val: any[]): void {
    this.reduxStore.dispatch(editorActions.setMultiSameTargetEdgeIds(val));
  }

  setPreSelectNodeId(val: any): void {
    this.reduxStore.dispatch(fotActions.setPreSelectNodeId(val));
  }

  setEnableTooltipWhatTodo(val: any): void {
    this.reduxStore.dispatch(editorActions.setEnableTooltipWhatTodo(val));
  }

  setShowTooltip(val: any): void {
    this.reduxStore.dispatch(editorActions.setShowTooltip(val));
  }

  setCloseToolTipArr(val: any): void {
    this.reduxStore.dispatch(editorActions.setCloseToolTipArr(val));
  }

  setNeedDisableEdgeIds(val: any): void {
    this.reduxStore.dispatch(editorActions.setNeedDisableEdgeIds(val));
  }

  setIsEdgeRunning(val: any): void {
    this.reduxStore.dispatch(editorActions.setIsEdgeRunning(val));
  }

  setStartNodeIds(val: any): void {
    this.reduxStore.dispatch(fotActions.setStartNodeIds(val));
  }
  setCotSnapshotProjectId(val: any): void {
    this.reduxStore.dispatch(editorActions.setCotSnapshotProjectId(val));
  }

  setEnableToolboxFeature(val: boolean): void {
    this.reduxStore.dispatch(fotActions.setEnableToolboxFeature(val));
  }

  setStudioProjectName = (val: string): void => {
    this.reduxStore.dispatch(fotActions.setStudioProjectName(val));
  };
  setShare(val: boolean): void {
    this.reduxStore.dispatch(fotActions.setShare(val));
  }

  setSubscriberParamsData(val: Record<string, any>): void {
    this.reduxStore.dispatch(editorActions.setSubscriberParamsData(val));
  }
  setMode(val: string): void {
    this.reduxStore.dispatch(fotActions.setMode(val));
  }
  setView(val: string): void {
    this.reduxStore.dispatch(fotActions.setView(val));
  }
  setStudioThought(val: any): void {
    this.reduxStore.dispatch(studioActions.setThought(val));
  }
  setFetching(value: boolean): void {
    this.reduxStore.dispatch(fotActions.setFetching(value));
  }
  setInputId(val: any): void {
    this.reduxStore.dispatch(fotActions.setInputId(val));
  }

  setCreateJSFuncData(jsFunctionData: FunctionJsPageData): void {
    this.reduxStore.dispatch(editorActions.setCreateJSFuncData(jsFunctionData));
  }
}
