import { useBrowerSuggestionTip } from "@uiview/views/BrowerSuggestionTip/useBrowerSuggestionTip";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FotProcessStore } from "@uikit/store/FotProcessStore";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import func from "@uikit/func";
import { getIt } from "@uikit/getIt";
import { logEvent } from "@uikit/service/amplitude";
import { useEffect } from "react";
import { defaultApis } from "@views/thinking-layout-editor/nodeTypeComponents/nodeTypeDispose";
import { FotProcessDep } from "@uikit/fotProcess/modles";
import { ProcessService } from "@uikit/fotProcess/ProcessService";
import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { SubscriptionStore } from "imagica-corekit/dist/cases/store/SubscriptionStore";
import { WebConfigStore } from "imagica-corekit/dist/cases/webconfig/WebConfigStore";
import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { initSDK } from "@uikit/service/birdEatsBugSDKInit";

let instance: FotProcessManager;
function useProcessManager(): FotProcessManager {
  const reactLocation = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlParam = useParams();
  const { setBrowerSuggestionTip } = useBrowerSuggestionTip();

  if (!instance) {
    instance = new FotProcessManager(
      getIt(FotReduxStore),
      getIt(FotProcessStore),
      getIt(ProcessService),
      getIt(WebConfigStore),
      getIt(SubscriptionStore),
      new FotProcessDep(reactLocation, searchParams, urlParam, window.history, window.location)
    );
  }

  useEffect(() => {
    instance.setDependencies({
      navigate,
      setBrowerSuggestionTip,
      onShareDomain: instance.onShareDomain.bind(instance),
      reactLocation,
      searchParams,
      urlParam,
      history: window.history,
      location: window.location,
    });
  });

  // 随便找了个地方,将 三个ui属性更新到 FotNavigateController 中
  getIt(FotNavigateController).setDependencies({ navigate, hisotry: window.history, location: reactLocation });

  return instance;
}

/**
 * FIXME: 该 manager 因为依赖大量 ui 层数据，后期需要和 ProcessService 合并
 */
export class FotProcessManager implements FotUIDependenciesInterface<FotProcessDep> {
  static use = useProcessManager;
  dependencies: FotProcessDep;

  constructor(
    public fotReduxStore: FotReduxStore,
    public fotProcessStore: FotProcessStore,
    public processService: ProcessService,
    public webConfigStore: WebConfigStore,
    public subscriptionStore: SubscriptionStore,
    initFotProcessDep: FotProcessDep
  ) {
    this.dependencies = initFotProcessDep;
  }

  setDependencies(value: FotProcessDep): void {
    Object.assign(this.dependencies, value);
  }

  async init(): Promise<void> {
    try {
      this.fotProcessStore.startLoading();

      await this.processService.init(this.dependencies);

      //
    } catch (error) {
      console.error("init error", error);
      func.messageUtil("Init page failed");
    }

    this.fotProcessStore.overLoading();
    this.fotReduxStore.setIsLogin(this.processService.processLang.isAuth());
  }

  /**
   * 原来 onShare 方法的逻辑
   *
   * @param isShare
   */
  async onShareDomain(isShare: boolean): Promise<void> {
    // 原来 onShare 方法的逻辑
    //如果是creator,清空GUEST_TOKEN，修复BSF-7328
    if (!isShare) {
      // 如果是creator,清空GUEST_TOKEN，修复BSF-7328
      window.sessionStorage.removeItem("GUEST_TOKEN");
      await this.webConfigStore.get();
      this.subscriptionStore.renew();
    }
    this.dependencies.setBrowerSuggestionTip?.(isShare);
  }

  /**
   * 该方法用于 getProject 错误处理方法
   * @param error
   */
  onImagicaProjectError(error: unknown): void {
    console.log("getProject error", error);

    this.dependencies.navigate?.("/editor");
    window.location.reload();
  }

  logout(): void {
    logEvent("logout");

    this.processService.processUserAuth.logout();

    this.dependencies.navigate?.("/signin");
  }

  private getSearchParams(originHref: string): URLSearchParams {
    return new URL(originHref.replace("#/", "")).searchParams;
  }

  /**
   * 原来 useHomeInit handleInit 调用
   */
  async initImagicaInit(): Promise<void> {
    // init bird SDK
    try {
      initSDK();
    } catch (error: any) {
      console.error("init bird sdk failed", error.message);
    }

    try {
      const searchParams = this.getSearchParams(window.location.href);

      await this.processService.processImagicaData.init({
        defaultApis,
        urlSelectedUI: searchParams.get("urlSelectedUI"),
        urlStripePaymentActivated: searchParams.get("stripePaymentActivated"),
        urlQparams: searchParams.get("q"),
        attrIndexer: this.dependencies.urlParam.attrIndexer,
        routerProjectId: this.dependencies.urlParam.projectId,
        onProjectError: this.onImagicaProjectError.bind(this),
      });
    } catch (error) {
      // 捕获原来 useHomeInint handleInit 401
      if ((error as any).name === 401) {
        this.logout();
      }
    }
  }
}
