import { FotFunctionJSPage } from "@uikit/cases/fotNavigate/FotFunctionJSPage";
import { FotFunctionNoramlPage } from "@uikit/cases/fotNavigate/FotFunctionNoramlPage";
import { FotNavigateController } from "@uikit/service/FotNavigateController";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { FotReduxStore } from "@uikit/store/FotReduxStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { ServiceIdentifier } from "imagica-corekit/dist/base/cutil/ServiceIdentifier";
import { Container } from "inversify";

/**
 * 导航路由相关的注册类
 */
export class RegisterFotNavigate {
  static configure(container: Container): void {
    const it = <T>(serviceIdentifier: ServiceIdentifier<T>): T => {
      return container.get(serviceIdentifier);
    };

    // 该 class 应该被 下面各 page 继承,但是实现了 FotUIDependenciesInterface 使用到了ui层的 navigate 需要在ui层动态设置
    const fotNavigateBase = it(FotNavigateController);
    container
      .bind(FotFunctionNoramlPage)
      .toConstantValue(new FotFunctionNoramlPage(fotNavigateBase, it(ShowNavPageStore), myModificationDataStroe));
    container
      .bind(FotFunctionJSPage)
      .toConstantValue(
        new FotFunctionJSPage(fotNavigateBase, it(ProjectFunctionStore), it(FotReduxStore), it(UiFunctionService))
      );
  }
}
