import { NewEdgeData, OldEdgeData, UpdateParam, FilterFarget, LineParam } from "@uikit/project/HomeUsePluginData";
import React, { MutableRefObject } from "react";
import { signal } from "@preact/signals-react";
import { NodeProps } from "reactflow";
import { find, get, isEmpty, map } from "lodash";
import func from "@uikit/func";
import { getIt } from "../../../uikit/getIt";
import { getFunctionNameByAlias } from "../../../views/thinking-layout-editor/nodeTypeComponents/nodeTypeDispose";
import {
  FUNCTION_REG,
  ENABLE_SPLIT_TYPES,
  DISABLE_AUTO_RUN_LIST,
  IMAGE_GEN_SELECT_LIST,
  INPUT_DEFAULT_WIDTH,
  INPUT_MAX_WIDTH,
  GROUPID_REG,
} from "../../../views/thinking-layout-editor/constants";
import { editorActions } from "../../../store/editor";
import { studioActions } from "../../../store/studio";
import { fotActions } from "../../../store/fot";
import style from "./CustomNewEdge.module.scss";
import { DropDownProps, InputRef } from "antd";

import skipEmptyVerification from "@uiview/views/Nodes/Agent/skipEmptyVerification";
import { StudioProjectAttributesV3Node } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3Node";
import { StudioProjectAttributesV3NodeData } from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV3NodeData";
import { logEvent } from "@uikit/service/amplitude";
import { uploadTypes } from "@uiview/views/Nodes/constants";
import { CustomNewEdgeUtil } from "@uiview/views/CustomNewEdge/CustomNewEdgeUtil";
import {
  StudioProjectAttributesV2EdgeData,
  StudioProjectV3EdgeFlow,
} from "imagica-corekit/dist/base/project/domain/StudioProjectAttributesV2Edge";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { CreateFunctionPageBackMsg } from "@uikit/msg/CreateFunctionPageBackMsg";
import { ProjectUtil } from "imagica-corekit/dist/cases/util/ProjectUtil";
import { BLUEPRINT } from "../BluePrint/CreateAGenerativeFunction";
import { BrainClient } from "imagica-corekit/dist/base/api/BrainClient";
import { tryPromise } from "imagica-corekit/dist/base/cutil/LangUtil";
import { FunctionSuggestions } from "imagica-corekit/dist/base/api/functionSuggestionsTyped/FunctionSuggestions";
import { JsonUtil } from "imagica-corekit/dist/base/cutil/JsonUtil";
import { StoryNodeType } from "imagica-corekit/dist/base/storyV2/domain/StoryNodeType";
import { FotClient } from "imagica-corekit/dist/base/api/FotClient";
import { HomeStore, GroupCOEStatus } from "imagica-corekit/dist/cases/store/HomeStore";
import isBlank from "@sedan-utils/is-blank";
import { MeStore } from "imagica-corekit/dist/base/store/MeStore";
import { PersonalFunctionStore } from "imagica-corekit/dist/base/store/PersonalFunctionStore";
import { creatorRefStore } from "@uikit/store/CreatorRefStore";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { GenUtil } from "imagica-corekit/dist/base/cutil/GenUtil";
import { HomeMethodsUtil } from "@uikit/util/_homeUtil";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { EdgeRunService } from "@uikit/edgeRun/EdgeRunService";
import { ConnectAgentService } from "@uikit/service/ConnectAgentService";
import { NodeEdgeInfoUpdater } from "@uikit/service/NodeEdgeInfoUpdater";
import { ShareAppService } from "@uikit/service/ShareAppService";
import { EdgeRunQuery } from "@uikit/edgeRun/EdgeRunQuery";
import { RunEdgeAddNodeToPreview } from "@uikit/service/RunEdgeAddNodeToPreview";
import { CreatorStoreMethods } from "@uikit/service/CreatorStoreMethods";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { FotFunctionJSPage, FunctionJsPageData } from "@uikit/cases/fotNavigate/FotFunctionJSPage";
class Props {
  store: any;
  dispatch: any;
  navigate: any;
  deleteElements: (param: any) => void = () => {};
}
class ExtraProps {
  edgeRunService?: EdgeRunService;
  disabledRun: boolean = false;
  sourceNodeId: string = "";
  isEdgeSelected: MutableRefObject<boolean> = { current: false };
  isEdgeClicked: MutableRefObject<boolean> = { current: false };
  isManualRun: MutableRefObject<boolean> = { current: false };

  prevAiDescription: MutableRefObject<string> = { current: "" };
  prevEdgeData: MutableRefObject<StudioProjectAttributesV2EdgeData> = {
    current: new StudioProjectAttributesV2EdgeData(),
  };

  commandInputRef: MutableRefObject<InputRef | undefined> = { current: undefined };
  aiDescriptionSpanRef: MutableRefObject<HTMLSpanElement | undefined> = { current: undefined };
  edgeTextRef: MutableRefObject<HTMLParagraphElement | undefined> = { current: undefined };
  edgeBoxRef: MutableRefObject<HTMLDivElement | undefined> = { current: undefined };

  edgeData: NewEdgeData & OldEdgeData = Object.assign(new NewEdgeData(), new OldEdgeData());
  edgeProps: NodeProps = {
    selected: false,
    isConnectable: false,
    dragging: false,
    id: "",
    type: "",
    zIndex: 0,
    xPos: 0,
    yPos: 0,
    data: {},
  };

  container: any;
  createFunctionClick: any;
  imageGenSelectOptions: any;
  hoverEdgeData: any;
  isDraggingCreatingNewLine: any;
  selectedTemplate: any;
  imagicaStudioTutorial: any;
  needDisableEdgeIds: any;
  createAPIFuncData: any;
  createJSFuncData: any;
  enableToolboxFeature: any;
  checkEdgeArr: any;
  currentModalLineParamStr: any;

  getHomeNodesFunc: () => any[] = () => [];
  setCreateFunctionClick: (param: any) => any = () => {};
  focusEdgeById: (id: string, options?: any) => any = () => {};
  onNodeContentChange: (nodesData: any) => void = () => {};
  setEdgeLineParam: (newLineParam: any) => void = () => {};
}
export class CustomNewEdgeState {
  suggestedLoading = false;

  selectValue = "stockInfo";
  aiDescriptionWidth = INPUT_DEFAULT_WIDTH;

  suggestedFunctions = undefined;

  sourceNodeContent = []; /// 前面一个node的 content，如果是group，保存所有数据
  suggestedQueries = [];
  lineBackgroundStyl = {};
  dropdownAlign = {
    offset: [100, 10],
    overflow: {
      adjustX: false,
      adjustY: false,
    },
  };

  copyWith(params: {
    suggestedLoading?: boolean;
    selectValue?: string;
    aiDescriptionWidth?: number;
    lineBackgroundStyl?: any;
    suggestedFunctions?: any;
    sourceNodeContent?: string[];
    suggestedQueries?: string[];
    dropdownAlign?: DropDownProps["align"];
  }): CustomNewEdgeState {
    return GenUtil.copyWith(new CustomNewEdgeState(), this, params);
  }
}
export class CustomNewEdgeBloc {
  fotClient = getIt(FotClient);
  brainClient = getIt(BrainClient);
  personalFunctionStore = getIt(PersonalFunctionStore);
  uiFunctionService = getIt(UiFunctionService);
  homeStore: HomeStore = getIt(HomeStore);
  meStore = getIt(MeStore);
  chatOnboardingStore = getIt(ChatOnboardingStore);
  creatorNodesStore = getIt(CreatorNodesStore);
  creatorEdgesStore = getIt(CreatorEdgesStore);
  nodeEdgeInfoUpdater = getIt(NodeEdgeInfoUpdater);
  shareAppService = getIt(ShareAppService);
  creatorStoreMethods = getIt(CreatorStoreMethods);
  fotSaveService = getIt(FotSaveService);

  /**
   * 原来 customNewEdge 中的 useNewEdgeRun hook
   *
   * 没有 errorClosure 回调
   */
  edgeRunService = getIt(EdgeRunService);
  connectAgentService = getIt(ConnectAgentService);
  edgeRunQuery = getIt(EdgeRunQuery);
  runEdgeAddNodeToPreview = getIt(RunEdgeAddNodeToPreview);
  fotFunctionJSPage = getIt(FotFunctionJSPage);
  extraProps: ExtraProps = new ExtraProps();

  state = signal(new CustomNewEdgeState());

  constructor(private props: Props) {}

  isFunc: boolean = false;

  setHoverEdgeData = (val: any): void => {
    this.props.dispatch(editorActions.setHoverEdgeData(val));
  };
  setSelectedTemplate = (val: any): void => {
    this.props.dispatch(studioActions.setSelectedTemplate(val));
  };
  setNeedDisableEdgeIds = (val: any): void => {
    this.props.dispatch(editorActions.setNeedDisableEdgeIds(val));
  };

  setCheckNodeArr = (val: any): void => {
    this.props.dispatch(fotActions.setCheckNodeArr(val));
  };

  setExtraProps(extraProps: ExtraProps): void {
    this.extraProps = extraProps;
    this.isFunc = this.extraProps.createAPIFuncData?.open || this.extraProps.createJSFuncData?.open;
  }

  initParam(): void {
    this.extraProps.container.selectedBlueprintsSingle.value =
      this.extraProps.edgeData.lineParam.selectedBlueprints ?? [];
  }

  outClickBindObj = {
    deleteEdge: (forceDelete: boolean = false): void => {
      setTimeout(() => {
        // 标记点击 不需要input失去焦点后删除node的 元素（元素需要设置成能获取焦点的标签类型，如input、button等）
        const noAutoDelElNames = ["undoButton", "redoButton"];
        if (
          (document.activeElement && document.activeElement.nodeName !== "BODY" && !forceDelete) ||
          noAutoDelElNames.includes((document.activeElement as any)?.name)
        ) {
          return;
        }

        const edges = this.creatorEdgesStore.getEdges() as unknown as any[];
        const latestEdge = edges[edges.length - 1];

        if (latestEdge.source === this.extraProps.edgeProps.id) {
          this.props.deleteElements({
            nodes: [{ id: latestEdge.source }, { id: latestEdge.target }],
          });
        }
      });
    },
    onOutClick: (e: any): void => {
      // console.log('---onOutClick')
      // 当点击除 customDrodow 时，包含边上的输入框，其余情况都关闭
      const textAreaDom = this.extraProps.container.aiDescriptionInputRef.current?.resizableTextArea?.textArea;
      const isFocus = textAreaDom == document.activeElement;
      // 此时没有聚焦到input输入框，并且显示了dropdown组件。那么需要隐藏dropdown组件，并且将文本组装到一起
      if (this.extraProps.container.showImageGenSelectSingle.value && !isFocus) {
        this.extraProps.container.onChangeVisibleCommandBar(false, e.target);
        this.hideCommandBarWhenBlur();
        return;
      }
      this.hideCommandBarWhenBlur();
      if (textAreaDom && textAreaDom === document.activeElement) {
        return;
      }

      this.outClickBindObj.handleCloseCustomDrop(e);
    },
    handleCloseCustomDrop: (e?: any, forceDelete: boolean = false): void => {
      // 记录是否是第一次运行 run
      const isFirstRun = !this.extraProps.prevAiDescription.current;
      // 1. 第一次点击
      // 1.1 有无内容 直接关闭 并删除节点
      if (isFirstRun && !this.isFunc) {
        this.outClickBindObj.deleteEdge(forceDelete);
        return;
      }
      // 2. 第二次点击
      // 2.1 与之前内容一样 关闭
      // 2.2 与之前内容不一样 还原之前的内容 关闭
      const prevEnterText = this.extraProps.edgeData.lineParam.enterText;
      const trimAiDescription = this.extraProps.container.aiDescriptionSingle.value.trim();
      if (prevEnterText === trimAiDescription) {
        this.closeCustomDropdown(e);
      } else {
        this.extraProps.container.aiDescriptionSingle.value = prevEnterText;
        this.closeCustomDropdown(e);
      }
    },
  };
  handleCreateFunctionPageBackMsg(msg: CreateFunctionPageBackMsg): void {
    if (msg.edgeId === this.extraProps.edgeProps.id) {
      if (this.extraProps.edgeData.lineParam.enterText.trim() === "") {
        this.outClickBindObj.handleCloseCustomDrop(undefined, true);
        return;
      } else {
        this.recoverRecordTextWhenBlur();
      }
    }
  }
  async onPressEnter(e: React.KeyboardEvent<HTMLTextAreaElement>): Promise<void> {
    this.extraProps.isManualRun.current = true;
    const text = (e.target as HTMLTextAreaElement).value;

    this.extraProps.container.onChangeVisibleCommandBar(false);

    if (this.extraProps.edgeData.lineParam.enterText !== text && !this.chatOnboardingStore.state.isOpenChat) {
      this.chatOnboardingStore.setChatOnboardingState({ canvasChangesOutOfSync: true });
    }

    /// 是否是upload file类型的节点
    if (CustomNewEdgeUtil.isUploadType(this.extraProps.edgeData, uploadTypes, this.extraProps.getHomeNodesFunc)) {
      this.handleUploadFileEnter(text);
      this.extraProps.container.aiDescriptionInputRef.current?.blur();
      return;
    }

    // 下拉框选择模式
    if (FUNCTION_REG.test(this.extraProps.container.aiDescriptionSingle.value)) {
      const currentOption =
        !isEmpty(this.extraProps.container.apiArrSignale.value) &&
        this.extraProps.container.apiArrSignale.value.length === 1
          ? this.extraProps.container.apiArrSignale.value[0]
          : this.extraProps.imageGenSelectOptions.find((x: any) => x.value === this.state.value.selectValue);
      this.clickToSelectValue(currentOption);
      this.extraProps.container.aiDescriptionInputRef.current?.blur();
      return;
    }

    this.handlePromptGeneration(text);
  }
  onCommandKeyDown(e: any): void {
    if (e.keyCode === 8 && func.isEmpty(this.extraProps.container.searchFuncSignal.value)) {
      if (this.homeStore.state.featureTags.showSuggestionFunctions) {
        this.extraProps.container.aiDescriptionInputRef.current?.focus();
      } else {
        this.extraProps.container.onChangeVisibleCommandBar(
          false,
          this.extraProps.container.aiDescriptionInputRef.current as HTMLTextAreaElement
        );
      }
    }
  }
  /// 修复 dropdown 弹出时，边的 target 被删除导致 onOutClick 调用异常问题
  onComponetDestory(): void {
    if (this.extraProps.container.showImageGenSelectSingle.value) {
      this.extraProps.container.onChangeVisibleCommandBar(false);
    }
  }
  onAiDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    // if(data.lineParam.loading) return;
    const text = e.target.value;
    const trimText: string = text.trim();
    const enableToolboxFeature = this.props.store.getState().fot.enableToolboxFeature;
    if (isEmpty(text)) {
      this.updateSourceNodeContent();
    }

    // blueprint的边清空数据
    if (
      enableToolboxFeature &&
      CustomNewEdgeUtil.isNormalEdgeByEdgeText(trimText) &&
      this.extraProps.container.selectedBlueprintsSingle.value.length !== 0
    ) {
      this.extraProps.container.selectedBlueprintsSingle.value = [];
    }

    // 输入为空重置下拉框
    // if(util.isEmpty(trimText)) {
    // this.extraProps.container.showImageGenSelectSingle.value = false
    // this.extraProps.container.onChangeVisibleCommandBar(false)
    // }

    //  没有下拉选项且输入框中超过3个字时，不显示下拉框
    const hasApiArr = this.extraProps.container.apiArrSignale.value.length;

    const isLessThan3Words = !hasApiArr && text.split(" ")?.length > 3 ? false : true;
    const visibleCommandPanel = isLessThan3Words || enableToolboxFeature;

    this.extraProps.container.showImageGenSelectSingle.value = visibleCommandPanel;
    if ((isEmpty(trimText) || trimText === "/") && !isEmpty(this.extraProps.container.searchFuncSignal.value)) {
      this.extraProps.container.searchFuncSignal.value = "";
    }
    const isBlueprintSearch = enableToolboxFeature && (trimText.indexOf("/") > -1 || trimText.indexOf("$") > -1);
    // 下拉框选择模式
    if (FUNCTION_REG.test(trimText) || isBlueprintSearch) {
      // isBlueprintSearch下不要加过滤
      let apiArrs = this.getFilterSelectOptions(isBlueprintSearch ? "" : trimText);
      const sourceNode = CustomNewEdgeUtil.getCurrNode(
        CustomNewEdgeUtil.getSourceNodeId(this.extraProps.edgeData),
        this.extraProps.edgeData,
        this.extraProps.getHomeNodesFunc
      );
      const sourceNodeType = (sourceNode?.data as StudioProjectAttributesV3NodeData)?.displayType;
      if (sourceNodeType && !ENABLE_SPLIT_TYPES.includes(sourceNodeType)) {
        apiArrs = apiArrs.filter((x: any) => x.value !== "splitOutput");
      }
      this.extraProps.container.apiArrSignale.value = apiArrs;

      // 下拉选项没显示出来时执行
      if (!this.extraProps.container.showImageGenSelectSingle.value) {
        // this.extraProps.container.showImageGenSelectSingle.value = true
        this.extraProps.container.onChangeVisibleCommandBar(true, undefined, visibleCommandPanel);
      }
    }
    //当前输入框是以/开头的，那么需要组装文本。(关闭toolbox时)
    if (text.startsWith("/") && enableToolboxFeature === false) {
      const splits = text.split("/");
      this.extraProps.container.aiDescriptionSingle.value = "/";
      // Fix me: 可能应该在下拉框显示的时候才将值赋给输入框searchFuncSignal.value，现在这样实现测试未发现会引发新的bug，如果将来发现有问题，可尝试设置个中间值
      this.extraProps.container.searchFuncSignal.value = this.extraProps.container.searchFuncSignal.value + splits[1];

      return;
    }
    this.extraProps.container.aiDescriptionSingle.value = text;
    // setHideEditInput(trimText !== this.extraProps.prevAiDescription.current.trim())
  }
  onMouseEnterEdge(): void {
    const lineIds = this.getLineIds();
    // 如果hover之前已经选中，则标记下以免leave时清除选中状态
    this.extraProps.isEdgeSelected.current = this.extraProps.checkEdgeArr.some((x: string) => {
      return lineIds.includes(x);
    });
    this.setHoverEdgeData({
      state: true,
      lineIds: this.getLineIds(),
      edgeId: this.extraProps.edgeProps.id,
      isSelected: this.extraProps.hoverEdgeData.isSelected,
    });
  }
  onMouseLeaveEdge(): void {
    this.setHoverEdgeData({
      state: false,
      lineIds: this.getLineIds(),
      edgeId: this.extraProps.edgeProps.id,
      isSelected: this.extraProps.hoverEdgeData.isSelected,
    });
  }

  updateApiArr(): void {
    this.extraProps.container.apiArrSignale.value = this.uiFunctionService.renew();
  }

  getLineIds(): string[] {
    const edges = this.creatorEdgesStore.getEdges() as unknown as any[];
    const lines = edges.filter(
      (x: any) => x.source === this.extraProps.edgeProps.id || x.target === this.extraProps.edgeProps.id
    );

    return lines.map((x: any) => x.id);
  }

  async getFunctionMapping(text: string): Promise<string> {
    try {
      // setEdgeState('generating')
      // 使用文本查询输入内容类型
      const res = await this.fotClient.generalApi.post("/be/cot/function/mapping", { description: text });
      const functionType = res?.data?.function_type || "";
      const functionNameFromAlias = getFunctionNameByAlias(functionType);
      const imageGenSelectOptionsArr = this.props.store.getState().fot.imageGenSelectOptions;
      const functionMappingData = imageGenSelectOptionsArr.find((x: any) => x.label === functionNameFromAlias);
      // 匹配已有 function ? 返回定义function : 默认为空
      return !isEmpty(functionMappingData) ? functionMappingData : null;
    } catch (error) {
      console.error("Get domain mapping failed:", error);
      return "";
    }
  }
  // eslint-disable-next-line
  getFilterSelectOptions(text: string) {
    let imageGenSelectOptionsArr = this.props.store.getState().fot.imageGenSelectOptions;
    imageGenSelectOptionsArr = this.uiFunctionService.get();
    const textFilter = text.replace(/^\//, "");
    const result = imageGenSelectOptionsArr.filter((x: any) =>
      x.label?.toLocaleLowerCase()?.includes(textFilter?.toLocaleLowerCase())
    );
    return result;
  }
  // eslint-disable-next-line
  getSuggestionFuntions(functionSuggestions: FunctionSuggestions) {
    const functions = functionSuggestions.functions;
    let imageGenSelectOptionsArr = this.props.store.getState().fot.imageGenSelectOptions;
    imageGenSelectOptionsArr = this.uiFunctionService.get();
    return imageGenSelectOptionsArr.filter((x: any) => {
      return functions.map(x => x.toLocaleLowerCase()).includes(x.label.toLocaleLowerCase());
    });
  }
  // eslint-disable-next-line
  getSuggestionQeuries(functionSuggestions: FunctionSuggestions) {
    const values: string[] = [];
    for (const key in functionSuggestions.edge_descriptions) {
      // eslint-disable-next-line
      if (functionSuggestions.edge_descriptions.hasOwnProperty(key)) {
        const value = functionSuggestions.edge_descriptions[key];
        values.push(value);
      }
    }
    return values;
  }
  getCustomEdgeClass(): string {
    const classes = [
      `${style["custom-edge-box"]} nodrag nopan`,
      // `${isModelOpen && lineParam.id === id || showImageGenSelect ? 'model-location' : ''}`,
    ];
    return classes.join(" ");
  }
  getCorrespondingPrevNodeContent(): any[] {
    const prevNodeContent: any[] = [];
    // 是group
    if (GROUPID_REG.test(this.extraProps.edgeData.targetNodeId)) {
      const childNodes = CustomNewEdgeUtil.getChildNodes(
        this.extraProps.edgeData.targetNodeId,
        this.extraProps.edgeData,
        this.extraProps.getHomeNodesFunc
      );
      childNodes.forEach((node: StudioProjectAttributesV3Node) => {
        this.setPrevNodeContent(prevNodeContent, node);
      });
    } else {
      const targetNode = CustomNewEdgeUtil.getCurrNode(
        this.extraProps.edgeData.targetNodeId,
        this.extraProps.edgeData,
        this.extraProps.getHomeNodesFunc
      ) as StudioProjectAttributesV3Node;
      this.setPrevNodeContent(prevNodeContent, targetNode);
    }
    return prevNodeContent;
  }

  updateSourceNodeContent(): void {
    const nodes = this.extraProps.getHomeNodesFunc();
    const sourceNodeData = nodes.find((value: { id: string }) => {
      return value.id === this.extraProps.sourceNodeId;
    });
    if (!sourceNodeData) {
      return;
    }
    if (!sourceNodeData.data) {
      return;
    }
    if (isBlank(sourceNodeData.data.textAreaValue)) {
      return;
    }
    const sourceNodeIsGroup = sourceNodeData.type === StoryNodeType.CUSTOMGROUP;
    if (sourceNodeIsGroup) {
      const childNode = sourceNodeData.data.childNode || [];
      const sourceNodeContent = nodes
        .filter((value: any) => {
          return childNode.includes(value.id) && !isEmpty(value.data.textAreaValue);
        })
        .map((value: any) => {
          return value.data.textAreaValue as string;
        });
      if (this.state.value.sourceNodeContent !== sourceNodeContent) {
        this.state.value = this.state.value.copyWith({
          sourceNodeContent: sourceNodeContent,
        });
      }
    } else {
      if (this.state.value.sourceNodeContent.first() !== sourceNodeData.data.textAreaValue) {
        this.state.value = this.state.value.copyWith({
          sourceNodeContent: [sourceNodeData.data.textAreaValue],
        });
      }
    }
  }

  async getSuggestedQueryAndFunctions(): Promise<void> {
    const nodes = this.extraProps.getHomeNodesFunc();
    const sourceNodeData = nodes.find((value: { id: string }) => {
      return value.id === this.extraProps.sourceNodeId;
    });
    this.extraProps.container.onChangeVisibleCommandBar(true);
    if (!sourceNodeData) {
      return;
    }
    if (!sourceNodeData.data) {
      return;
    }
    if (isBlank(sourceNodeData.data.textAreaValue)) {
      return;
    }

    if (this.extraProps.container.edgeStateSignal.value !== "input") {
      return;
    }

    if (this.state.value.suggestedLoading) {
      return;
    }

    this.state.value = this.state.value.copyWith({
      suggestedLoading: true,
    });
    const templateObj = this.props.store.getState().studio.selectedTemplate;
    const edges = this.creatorEdgesStore.getEdges() as unknown as any[];
    const variableList = this.props.store.getState().fot.variableList;
    const saveData = this.fotSaveService.getSaveDataV3(templateObj, edges, variableList, {});
    const graphJson = JsonUtil.parseFromInstance(saveData);
    const result = await tryPromise(
      this.brainClient.openAi.getSuggestedFunctions(this.extraProps.sourceNodeId, graphJson)
    );
    /// 加载数据完成
    this.state.value = this.state.value.copyWith({
      suggestedLoading: false,
    });
    if (!result.data) {
      this.state.value = this.state.value.copyWith({
        suggestedFunctions: [],
        suggestedQueries: [],
      });
      return;
    }

    if (result.data.data) {
      this.state.value = this.state.value.copyWith({
        suggestedFunctions: this.getSuggestionFuntions(result.data.data),
        suggestedQueries: this.getSuggestionQeuries(result.data.data),
      });
    }
  }

  setPrevNodeContent(prevNodeContent: any[], node: StudioProjectAttributesV3Node): void {
    const targetNodeData = node?.data as StudioProjectAttributesV3NodeData;
    if (!targetNodeData) return;
    prevNodeContent.push({
      id: node.id,
      textAreaValue: targetNodeData.textAreaValue,
      displayType: targetNodeData.displayType,
    });
  }

  getAllNextEdges(needDisableEdgeIds: Array<string>, targets: Array<string>): void {
    const allNodes = this.extraProps.getHomeNodesFunc();
    const customNewEdgeArr = allNodes.filter(x => x.type === "customNewEdge" && x.id !== this.extraProps.edgeProps.id);

    const nextEdges = customNewEdgeArr.filter(x => {
      const edgeData = x?.data as StudioProjectAttributesV2EdgeData;
      const flowsArr: StudioProjectV3EdgeFlow[] = edgeData?.flows || [];
      return (
        flowsArr.some((y: StudioProjectV3EdgeFlow) => targets.includes(y?.sourceNodeId || "")) &&
        !edgeData?.lineParam?.loading
      );
    });

    let nextEdgeTargets = nextEdges.map(x => {
      const edgeData = x?.data as StudioProjectAttributesV2EdgeData;
      return edgeData?.targetNodeId || "";
    });
    const nextEdgeIds = nextEdges.map(x => x?.id || "");

    // 处理分组
    const groupArr = targets.filter(x => GROUPID_REG.test(x));
    if (!func.isEmpty(groupArr)) {
      const childEdges = this.grouNextNodeId(groupArr);
      nextEdgeTargets = nextEdgeTargets.concat(childEdges);
    }

    needDisableEdgeIds.push(...nextEdgeIds);
    if (!isEmpty(nextEdgeIds) || !func.isEmpty(groupArr)) {
      this.getAllNextEdges(needDisableEdgeIds, nextEdgeTargets);
    }
  }
  // eslint-disable-next-line
  grouNextNodeId(groupArr: any) {
    const nodes = this.extraProps.getHomeNodesFunc();
    // 获取分组下所有子node
    const childNodeIdArr = nodes.filter(x => groupArr.includes(x.parentNode)).map(x => x.id);
    return childNodeIdArr;
  }
  // eslint-disable-next-line
  setRunDisable() {
    const target = this.extraProps.edgeData.targetNodeId;
    const needDisableEdgeArrIds: Array<string> = [];
    this.getAllNextEdges(needDisableEdgeArrIds, [target]);
    this.setNeedDisableEdgeIds([...this.extraProps.needDisableEdgeIds, ...needDisableEdgeArrIds]);
    return needDisableEdgeArrIds;
  }
  // eslint-disable-next-line
  checkSourceNotEmpty(): boolean {
    return ProjectUtil.sourceNodesAllFill(this.extraProps.edgeProps.id, this.extraProps.getHomeNodesFunc());
  }

  /**
   * 设置当前边的状态
   * @param loading
   */
  setEdgeLoading(loading: boolean): void {
    this.creatorNodesStore.setNodes((prevList: any) => {
      const result = prevList.map((x: any) => {
        if (x.id === this.extraProps.edgeProps.id) {
          return {
            ...x,
            data: {
              ...x.data,
              lineParam: {
                ...x.data.lineParam,
                loading: loading,
              },
            },
          };
        }
        return x;
      });
      return result;
    });
  }

  async clickRunBtn(
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    isManualClick: boolean,
    updateParam?: UpdateParam
  ): Promise<void> {
    e?.stopPropagation();
    const enterText = this.extraProps.edgeData.lineParam.enterText;
    const notHaveBlueJson = func.isEmpty(
      JSON.parse(updateParam?.lineParam.blueJson || this.extraProps.edgeData.lineParam.blueJson || "{}")
    );
    if (
      e &&
      this.extraProps.edgeData.lineParam.identifier === "" &&
      enterText !== "" &&
      !enterText.startsWith("/") &&
      notHaveBlueJson
    ) {
      this.setIsGetQuery(false);
      this.handlePromptGeneration(this.extraProps.edgeData.lineParam.enterText);
      return;
    }

    const lineParam = new LineParam();
    lineParam.enterText = updateParam?.lineParam.enterText || enterText;
    lineParam.description = updateParam?.lineParam.description || this.extraProps.edgeData.lineParam.description;
    lineParam.id = this.extraProps.edgeProps.id;
    this.nodeEdgeInfoUpdater.updateNodeText(lineParam);

    const disableNextEdgeArr = this.setRunDisable();
    if (this.extraProps.edgeData.lineParam.loading) {
      const templateObj = this.props.store.getState().studio.selectedTemplate;
      const projectId = (templateObj as any).index ?? "";
      this.edgeRunService.corekitRunStop(projectId, this.extraProps.edgeProps.id);
      return;
    }
    const sourceNode = this.extraProps
      .getHomeNodesFunc()
      .find(x => x.id === CustomNewEdgeUtil.getSourceNodeId(this.extraProps.edgeData));

    const targetNode = this.extraProps.getHomeNodesFunc().find(x => x.id === this.extraProps.edgeData.lineParam?.id);
    const input_nodes = map(get(targetNode, "data.flows"), ({ sourceNodeId }) => {
      const node = find(this.extraProps.getHomeNodesFunc(), node => node.id === sourceNodeId);
      const textareaValue = get(node, "data.textAreaValue");
      return {
        id: node?.id || "",
        data: typeof textareaValue === "string" ? textareaValue : undefined,
        display_type: get(node, "data.displayType", ""),
        type: node?.type,
      };
    });
    if (!this.checkSourceNotEmpty()) {
      if (isManualClick) {
        func.customMsg({
          content: "Need input value",
          type: "warning",
        });
      }
      return;
    }

    const prevNodeContent: any[] = this.getCorrespondingPrevNodeContent();
    let outputs;
    try {
      if (notHaveBlueJson) {
        const demoAgent = await this.handleDoAgentDomo();
        if (demoAgent) return;
      }

      const { transformData } = await this.edgeRunService.corekitRun<UpdateParam | undefined>({
        edgeId: this.extraProps.edgeProps.id,
        updateParam,
        onUpdateNode: this.shareAppService.setRunAllUpdateData,
      });
      // run完的时候重置group折叠状态
      if (GROUPID_REG.test(this.extraProps.edgeData.targetNodeId)) {
        this.homeStore.setGroupCOEData({
          groupId: this.extraProps.edgeData.targetNodeId,
          groupCOEStatus: GroupCOEStatus.Expand,
          collapseChildNodes: [],
        });
        this.creatorNodesStore.setNodes((l: any) => {
          return l.map((x: any) => {
            if (x.id === this.extraProps.edgeData.targetNodeId) {
              return {
                ...x,
                data: {
                  ...x.data,
                  showGroupCollapseData: false,
                },
              };
            }
            return x;
          });
        });
      }
      outputs = transformData;
      const outputNode = Array.isArray(transformData) ? transformData[0] : transformData;
      this.runEdgeAddNodeToPreview.firstRunAddNodeToPreview({ sourceNode, outputNode });
      this.extraProps.onNodeContentChange(
        HomeMethodsUtil.getStartEndNodesContentArr(prevNodeContent, undefined, this.creatorNodesStore.state.nodes)
      );
    } catch (error) {
      // eslint-disable-next-line
    } finally {
      newOnboardingFlowStore.showNewOnboardingFlowTips(this.extraProps.getHomeNodesFunc);
      this.showPublishPrompt();
      const closeRunDisable = this.props.store
        .getState()
        .editor.needDisableEdgeIds.filter((x: any) => !disableNextEdgeArr.includes(x));
      this.setNeedDisableEdgeIds(closeRunDisable);
      input_nodes && this.handleLogEvent(input_nodes, outputs, isManualClick ?? false, updateParam);
    }
  }
  async clickToHandlePromptGeneration(text: string): Promise<void> {
    this.handlePromptGeneration(text);
    this.extraProps.container.onChangeVisibleCommandBar(false);
  }
  async clickToSelectValue(j: any): Promise<void> {
    this.extraProps.isManualRun.current = true;
    let newAiDescription = "";
    const isBlueEdge = j.type && j.type === BLUEPRINT;
    const oldAiDescriptionSingle = this.extraProps.container.aiDescriptionSingle.value;
    const insertIndex =
      this.extraProps.container.aiDescriptionInputRef.current?.resizableTextArea?.textArea.selectionStart;
    const isBehindSlash = insertIndex && oldAiDescriptionSingle[insertIndex - 1] === "/";
    if (isBlueEdge) {
      // 光标前面必须是/ 并且第一个字符不能是/
      if (isBehindSlash && insertIndex !== 1) {
        this.autocompleteVocabulary({ id: j.id, description: oldAiDescriptionSingle });
        return;
      } else {
        newAiDescription = `${j.label}`;
      }
    } else {
      newAiDescription = `/${j.label}`;
    }
    if (
      this.extraProps.edgeData.lineParam.enterText !== newAiDescription &&
      !this.chatOnboardingStore.state.isOpenChat
    ) {
      this.chatOnboardingStore.setChatOnboardingState({ canvasChangesOutOfSync: true });
    }
    // logEvent('click_function', {function_name: j.value});
    this.handleSetPrevLineParamStr({
      enterText: newAiDescription,
    });

    this.extraProps.container.aiDescriptionSingle.value = newAiDescription;
    this.state.value = this.state.value.copyWith({
      selectValue: j.value,
    });
    // 隐藏下拉选项
    // this.extraProps.container.showImageGenSelectSingle.value = false
    this.extraProps.container.onChangeVisibleCommandBar(false);
    // 给按钮下的文本赋值
    CustomNewEdgeUtil.updateEdgeLineParam(
      this.extraProps.edgeData,
      this.extraProps.edgeProps.id,
      this.creatorNodesStore.setNodes,
      {
        enterText: newAiDescription,
        identifier: {},
        originFunctionData: {
          id: j?.id || "",
          value: j?.value || "",
        },
        blueJson: j?.blueJson,
      }
    );
    // 显示run按钮
    this.setIsGetQuery(true);
    // setHideEditInput(false)
    if (DISABLE_AUTO_RUN_LIST.includes(j.value)) return;
    this.clickRunBtn(undefined, false, {
      edgeId: this.extraProps.edgeProps.id,
      type: "lineParam",
      lineParam: {
        enterText: newAiDescription,
        identifier: {},
        blueJson: j?.blueJson,
        description: j?.description,
      },
    });
    this.extraProps.container.searchFuncSignal.value = "";
  }
  async autocompleteVocabulary(item: { id: string; description: string }): Promise<void> {
    const edge_description = item.description.slice(0, -1);
    const selected_blueprints = this.extraProps.container.selectedBlueprintsSingle.value;
    const result = await this.brainClient.openAi.blueprintVocabulary({
      id: item.id,
      edge_description,
      selected_blueprints,
    });
    if (result) {
      this.extraProps.container.selectedBlueprintsSingle.value = result.data.selected_blueprints;
      this.extraProps.container.aiDescriptionSingle.value = result.data.query;
    }
    this.extraProps.container.aiDescriptionInputRef.current?.focus();
  }
  async clickCreateFunction(item: any): Promise<void> {
    this.extraProps.container.onChangeVisibleCommandBar(false);
    if (item.value === "createCustomJSFunc") {
      const value = {
        open: true,
        sourceNodeId: CustomNewEdgeUtil.getSourceNodeId(this.extraProps.edgeData),
        targetNodeId: this.extraProps.edgeData.targetNodeId,
        state: "new",
        edgeId: this.extraProps.edgeProps.id,
        parameter: {
          name: "No function name",
          description: "",
        },
      } as FunctionJsPageData;
      this.fotFunctionJSPage.openCreate(value);
    } else if (item.value === "createAPIFunc") {
      const hasCustomApiV2 = await this.meStore.hasCustomApiV2();
      if (hasCustomApiV2) {
        this.props.navigate("function/Untitled");
      }
      const value = {
        open: true,
        sourceNodeId: CustomNewEdgeUtil.getSourceNodeId(this.extraProps.edgeData),
        targetNodeId: this.extraProps.edgeData.targetNodeId,
        state: "new",
        edgeId: this.extraProps.edgeProps.id,
        parameter: {
          name: "No function name",
          description: "",
        },
      };
      this.props.dispatch(editorActions.setCreateAPIFuncData(value));
    }
  }

  /// 处理前一个节点是file类型时的egde输入文字后的点击
  handleUploadFileEnter(text: string): void {
    /// 更新节点数据enterText
    const lineType = "prompt";
    const lineParam: any = { enterText: text, lineType: lineType };
    CustomNewEdgeUtil.updateEdgeLineParam(
      this.extraProps.edgeData,
      this.extraProps.edgeProps.id,
      this.creatorNodesStore.setNodes,
      lineParam
    );

    /// 显示run按钮
    this.setIsGetQuery(true);

    /// 点击run按钮
    this.clickRunBtn(undefined, false, {
      edgeId: this.extraProps.edgeProps.id,
      type: lineType,
      lineParam: lineParam,
    });
  }

  async handlePromptGeneration(text: string, needRun: boolean = true): Promise<void> {
    try {
      const sourceId = CustomNewEdgeUtil.getSourceNodeId(this.extraProps.edgeData);
      const currentSourceNode = CustomNewEdgeUtil.getCurrNode(
        sourceId,
        this.extraProps.edgeData,
        this.extraProps.getHomeNodesFunc
      );
      const textAreaValue = (currentSourceNode?.data as StudioProjectAttributesV3NodeData).textAreaValue;

      // 输入为空 或 node 没有值禁止调用接口
      if (func.isEmpty(text)) {
        func.customMsg({
          content: "Edge Description cannot be empty.",
          type: "warning",
        });
        return;
      }

      this.extraProps.container.aiDescriptionInputRef.current?.blur();

      // 先更新文本
      const lineParam: any = {
        enterText: this.extraProps.container.aiDescriptionSingle.value,
        lineType: "prompt",
        identifier: "",
        selectedBlueprints: this.extraProps.container.selectedBlueprintsSingle.value,
      };

      const lineParamForNodeTitle = new LineParam();
      lineParam.enterText = text;
      lineParam.id = this.extraProps.edgeProps.id;
      lineParamForNodeTitle.enterText = text;
      lineParamForNodeTitle.id = this.extraProps.edgeProps.id;
      this.nodeEdgeInfoUpdater.updateNodeText(lineParamForNodeTitle);

      CustomNewEdgeUtil.updateEdgeLineParam(
        this.extraProps.edgeData,
        this.extraProps.edgeProps.id,
        this.creatorNodesStore.setNodes,
        lineParam
      );
      this.handleSetPrevLineParamStr(lineParam);
      const res = await tryPromise(this.edgeRunQuery.getAndSetQueryV3(this.extraProps.edgeProps.id, text));
      if (res.error) {
        return;
      }
      const { identifier, lineType } = res.data as { lineType: string; identifier: any };

      this.handleSetPrevLineParamStr({
        enterText: text,
        lineType,
        identifier,
      });
      if (!identifier) {
        this.extraProps.container.aiDescriptionSingle.value = "";
        return;
      }

      // 如果左边node有内容，输入AI描述回车后应该自动run
      const enableSkipEmptyVerification =
        this.homeStore.state.featureTags.enableSkipEmptyVerification && skipEmptyVerification(text);
      if ((enableSkipEmptyVerification || !func.isEmpty(textAreaValue) || GROUPID_REG.test(sourceId)) && needRun) {
        this.clickRunBtn(undefined, false, {
          edgeId: this.extraProps.edgeProps.id,
          type: lineType ?? "lineType",
          lineParam: {
            enterText: this.extraProps.container.aiDescriptionSingle.value,
            identifier: identifier,
          },
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
  handleValueChange = (value: string): void => {
    this.extraProps.container.apiArrSignale.value = this.getFilterSelectOptions(value);
    this.extraProps.container.searchFuncSignal.value = value;
    if (!func.isEmpty(value) && this.extraProps.container.showImageGenSelectSingle.value === false) {
      this.extraProps.container.onChangeVisibleCommandBar(true);
    }
  };
  hideCommandBarWhenBlur(): void {
    const showCommandBar = this.extraProps.container.showImageGenSelectSingle.value;
    const activeElement = document.activeElement as any;
    const inputRef = this.extraProps.container.aiDescriptionInputRef.current;
    if (!inputRef) {
      return;
    }
    const input = inputRef && (inputRef as any).resizableTextArea.textArea;
    if (activeElement !== this.extraProps.container.commandInputRef?.current && activeElement !== input) {
      if (showCommandBar) {
        this.extraProps.container.aiDescriptionSingle.value =
          this.extraProps.container.aiDescriptionSingle.value + this.extraProps.container.searchFuncSignal.value;
        this.extraProps.container.searchFuncSignal.value = "";
        this.extraProps.container.onChangeVisibleCommandBar(false);
      }
      this.recoverRecordTextWhenBlur();
      return;
    }
    return;
  }

  /**
   * 当输入框失去焦点
   *
   */
  recoverRecordTextWhenBlur(): void {
    // console.log(`recoverRecordTextWhenBlur isManual: ${this.extraProps.isManualRun.current}`)
    // 如果点击了enter 或者 选了某个function，那么不进行处理
    if (this.extraProps.isManualRun.current) {
      return;
    }
    const oldText = this.extraProps.edgeData.lineParam.enterText;
    const aiDescription = this.extraProps.container.aiDescriptionSingle.value;
    // 文本没有改变，并且档期文本不为空，那么直接显示run
    if (!this.textHadChange() && aiDescription.trim() !== "") {
      this.setIsGetQuery(true);
      return;
    }
    if (aiDescription.startsWith("/")) {
      if (oldText.trim() === "") {
        //console.log('record.aiDescription is empty')
        this.outClickBindObj.handleCloseCustomDrop(undefined, true);
        return;
      }
      // 需要还原
      this.extraProps.container.aiDescriptionSingle.value = oldText;
    } else {
      if (oldText.trim() === "" && aiDescription.trim() === "") {
        this.outClickBindObj.handleCloseCustomDrop(undefined, true);
        return;
      }
      const aiDescriptionNew = aiDescription.trim() === "" ? oldText : aiDescription;
      this.extraProps.container.aiDescriptionSingle.value = aiDescriptionNew;
    }
    if (!this.extraProps.container.aiDescriptionSingle.value.startsWith("/")) {
      this.handlePromptGeneration(this.extraProps.container.aiDescriptionSingle.value, false);
    }
    this.setIsGetQuery(true);
  }
  setIsGetQuery(isGetQuery: boolean): void {
    this.creatorNodesStore.setQueryLoadingV3({
      id: this.extraProps.edgeProps.id,
      data: {
        isGetQuery: isGetQuery,
      },
    });
  }
  textHadChange(): boolean {
    // 获取到上一次的文本
    const oldText = this.extraProps.edgeData.lineParam.enterText;
    const aiDescription = this.extraProps.container.aiDescriptionSingle.value;
    const changed = oldText?.trim() !== aiDescription.trim();
    return changed;
  }
  handleEscape(): void {
    const inputRef = this.extraProps.container.aiDescriptionInputRef.current;
    this.extraProps.container.onChangeVisibleCommandBar(false);
    if (!inputRef) {
      return;
    }
    inputRef.blur();
  }
  handleInputBlur = (): void => {
    // 关闭边的时候会调用报错，但是没有清除禁用。
    // 不能在加载中的时候，关掉边。
    if (creatorRefStore.canvasMovingLoadingRef.current) return;
    // 如果下拉框已经打开 关闭的动作交给 outClick
    if (this.extraProps.container.showImageGenSelectSingle.value) return;
    if (this.textHadChange() || this.extraProps.container.aiDescriptionSingle.value.trim() === "") {
      this.hideCommandBarWhenBlur();
      return;
    }

    // input 有值不删除 node
    if (isEmpty(this.extraProps.container.aiDescriptionSingle.value)) {
      this.outClickBindObj.handleCloseCustomDrop();
    } else {
      this.hideCommandBarWhenBlur();
    }
  };
  handleDeveloperModeEffect(): void {
    if (this.extraProps.container.showImageGenSelectSingle.value) {
      this.extraProps.container.apiArrSignale.value = this.getFilterSelectOptions(
        this.extraProps.container.searchFuncSignal.value
      );
    }
  }
  handleCreateFunctionClickEffect(): void {
    // todo: 这里原代码判定的是edgeId，这里的edgeProps.id未来可能需要改动
    if (
      !isEmpty(this.extraProps.createFunctionClick) &&
      this.extraProps.createFunctionClick.edgeId === this.extraProps.edgeProps.id
    ) {
      /// 这里需要timeout
      /// 否则func创建后ui function service dirty操作会在这之后才执行
      /// 导致UI取不到最新创建的func
      setTimeout(() => {
        this.requestSavedFunction(this.extraProps.createFunctionClick.functionName);
      });
    }
  }
  handleDataAttrEffect(): void {
    if (!this.extraProps.edgeData.isSliceAutoRun) {
      if (
        !this.extraProps.edgeData.isFromSaveData &&
        !this.extraProps.container.showImageGenSelectSingle.value &&
        !this.extraProps.edgeData.isUndoRedoAdd
      ) {
        this.setCheckNodeArr([]);
        setTimeout(() => {
          this.extraProps.container.aiDescriptionInputRef.current?.focus();
        }, 500);
      }
      this.creatorNodesStore.setQueryLoadingV3({
        id: this.extraProps.edgeProps.id,
        data: {
          isUndoRedoAdd: false,
        },
      });
      const enterText = this.extraProps.edgeData?.lineParam?.enterText || "";
      if (enterText && enterText !== this.extraProps.prevAiDescription.current) {
        this.extraProps.container.aiDescriptionSingle.value = enterText;
      }
      const aiStr = enterText.replace(FUNCTION_REG, "");
      const arr = [...IMAGE_GEN_SELECT_LIST, ...this.extraProps.imageGenSelectOptions];
      const select = arr.find(x => x.label === aiStr);
      const selectVal = select?.value || "stockInfo";
      this.state.value = this.state.value.copyWith({
        selectValue: selectVal,
      });
    }
  }
  handleQueryEffect(): void {
    // console.log(`isGetQuery: ${this.edgeData.isGetQuery}, getQueryLoading: ${this.edgeData.getQueryLoading}`,)
    if (!this.extraProps.edgeData.isGetQuery) {
      if (!this.extraProps.edgeData.getQueryLoading) {
        this.extraProps.container.focusEdge.setEdgeState("input");
      } else {
        this.extraProps.container.focusEdge.setEdgeState("generating");
      }
    } else {
      this.extraProps.container.focusEdge.setEdgeState("run", () => {
        this.extraProps.container.onChangeVisibleCommandBar(false);
      });
    }
  }
  // eslint-disable-next-line
  getPosition(middlePostion: { x: number; y: number }, node: any) {
    return this.extraProps.edgeBoxRef.current
      ? {
          x: middlePostion.x - this.extraProps.edgeBoxRef.current.clientWidth / 2,
          y: middlePostion.y - this.extraProps.edgeBoxRef.current.clientHeight / 2,
        }
      : {
          x: middlePostion.x - node.width / 2,
          y: middlePostion.x - node.height / 2,
        };
  }
  handleEdgePositionEffect(middlePostion: { x: number; y: number }): void {
    this.creatorNodesStore.setNodes((prevList: any) => {
      return prevList.map((node: any) => {
        if (node.id === this.extraProps.edgeProps.id) {
          const position = this.getPosition(middlePostion, node);
          return {
            ...node,
            position,
          };
        }
        return node;
      });
    });
  }
  handleEdgeHoverEffect(): void {
    const edgeId = this.props.store.getState().editor.hoverEdgeData.edgeId;
    if (edgeId !== undefined && this.extraProps.edgeProps.id !== edgeId) {
      return;
    }
    const hoverData = this.extraProps.hoverEdgeData;
    if ((hoverData.state === false && !hoverData.isSelected) || !this.extraProps.edgeTextRef.current) {
      this.extraProps.isEdgeClicked.current = false;
      this.state.value = this.state.value.copyWith({
        lineBackgroundStyl: {
          height: (this.extraProps.edgeTextRef.current?.clientHeight ?? 0) + 60,
          border: "none",
          opacity: 0,
        },
      });
      // 边显示和消失(opacity)的动画时长为0.3s，先不改变高度，动画完成后再改变lineBG高度
      setTimeout(() => {
        this.state.value = this.state.value.copyWith({
          lineBackgroundStyl: {
            height: 0,
            border: "none",
            opacity: 0,
          },
        });
      }, 300);
      return;
    }

    /// 拖线的时候不需要边框
    if (this.extraProps.isDraggingCreatingNewLine.state) {
      return;
    }
    this.state.value = this.state.value.copyWith({
      lineBackgroundStyl: {
        height: this.extraProps.edgeTextRef.current.clientHeight + 60,
        border: "0.5px solid #FFF",
        opacity: 1,
      },
    });
  }
  handleShowImageGenSelectEffect(): void {
    if (!this.extraProps.container.showImageGenSelectSingle.value && !this.extraProps.edgeData.isUndoRedoAdd) {
      this.extraProps.container.aiDescriptionInputRef.current?.focus();
      return;
    }
    // 选中光标后默认 hover 效果
    if (this.extraProps.container.aiDescriptionSingle.value.includes("/")) {
      this.extraProps.container.disableHoverSignal.value = false;
      this.extraProps.commandInputRef.current?.focus();
    }
  }
  handleFuzzySearch(filterCompletedArray: FilterFarget[]): void {
    if (this.extraProps.container.edgeStateSignal.value !== "input") return;
    if (this.homeStore.state.featureTags.showEdgeBluePrint) {
      this.extraProps.container.onChangeVisibleCommandBar(true);
    }
    if (!isEmpty(filterCompletedArray)) {
      this.extraProps.container.disableHoverSignal.value = true;
      this.extraProps.container.apiArrSignale.value = filterCompletedArray;
      // this.extraProps.container.showImageGenSelectSingle.value = true
      this.extraProps.container.onChangeVisibleCommandBar(true);
    } else {
      this.extraProps.container.apiArrSignale.value = [];
      // this.extraProps.container.showImageGenSelectSingle.value = false
      // this.extraProps.container.onChangeVisibleCommandBar(false)
    }
  }
  handleSetPrevLineParamStr(param: any): void {
    const newLineParam = Object.assign({}, this.extraProps.edgeData.lineParam, {
      ...param,
    });
    const newLineParamStr = JSON.stringify(newLineParam);
    const prevLineParamStr = JSON.stringify(this.extraProps.prevEdgeData.current.lineParam || {});
    if (prevLineParamStr !== newLineParamStr) {
      this.creatorStoreMethods.onEdgeContentChange([
        {
          id: this.extraProps.edgeProps.id,
          startEdgeData: {
            isGetQuery: this.extraProps.prevEdgeData.current?.isGetQuery || false,
            lineParamStr: prevLineParamStr,
          },
          endEdgeData: {
            isGetQuery: true,
            lineParamStr: newLineParamStr,
          },
        },
      ]);
    }
  }
  handleCurrentModalLineParamStrEffect(): void {
    const currentModalLineParam =
      this.extraProps.currentModalLineParamStr && JSON.parse(this.extraProps.currentModalLineParamStr);
    if (currentModalLineParam.id !== this.extraProps.edgeProps.id) return;

    const prevLineParamStr = JSON.stringify(this.extraProps.prevEdgeData.current.lineParam || {});
    if (prevLineParamStr !== this.extraProps.currentModalLineParamStr && !this.extraProps.edgeData.isUndoRedoAdd) {
      //这里需要监听node内容改变
      this.creatorStoreMethods.onEdgeContentChange([
        {
          id: this.extraProps.edgeProps.id,
          startEdgeData: {
            isGetQuery: this.extraProps.prevEdgeData.current?.isGetQuery || false,
            lineParamStr: prevLineParamStr,
          },
          endEdgeData: {
            isGetQuery: true,
            lineParamStr: this.extraProps.currentModalLineParamStr,
          },
        },
      ]);
    }
    this.creatorNodesStore.setQueryLoadingV3({
      id: this.extraProps.edgeProps.id,
      data: {
        isUndoRedoAdd: false,
      },
    });
  }

  clickLine(): void {
    this.extraProps.isEdgeClicked.current = true;
    this.setHoverEdgeData({
      state: true,
      lineIds: this.getLineIds(),
      edgeId: this.extraProps.edgeProps.id,
      isSelected: true,
    });
  }
  doubleClickLine(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    // if (this.edgeData.lineParam.loading) return;
    // todo: 暂时屏蔽guide
    if (this.extraProps.edgeData.lineParam.loading) return;
    e.preventDefault();
    e.stopPropagation();
    /* this.textAreaWrapStyl.value = {
      position: "absolute",
      marginLeft: "77px",
      top: "50%",
      transform: "translateY(-50%)",
    }; */ // BSF-6075 输入框内容过长时，双击边框，tooltip会和输入框重叠
    this.extraProps.prevEdgeData.current = Object.assign(
      {},
      {
        ...this.extraProps.edgeData,
        lineParam: {
          ...this.extraProps.edgeData.lineParam,
        },
      }
    );
    this.extraProps.prevAiDescription.current = this.extraProps.container.aiDescriptionSingle.value;
    if (FUNCTION_REG.test(this.extraProps.container.aiDescriptionSingle.value)) {
      this.setIsGetQuery(false);
      this.onFocusInput();
      // this.extraProps.container.showImageGenSelectSingle.value = true
      this.extraProps.container.onChangeVisibleCommandBar(true);
      return;
    }
    this.setIsGetQuery(false);
    setTimeout(() => {
      const aiInput = this.extraProps.container.aiDescriptionInputRef.current;
      if (aiInput) {
        aiInput.focus();
        this.onFocusInput();
        const textArea = get(aiInput, "resizableTextArea.textArea");
        const valueLength = get(textArea, "value.length", 0);
        textArea?.setSelectionRange(valueLength, valueLength);
        this.resetAiDescriptionWidth();
      }
    }, 0);
  }
  async requestSavedFunction(name: string): Promise<void> {
    this.extraProps.container.apiArrSignale.value = this.getFilterSelectOptions("");
    const currentOption = this.extraProps.container.apiArrSignale.value.find((x: any) => x.value === name);
    if (func.isEmpty(currentOption)) return;
    this.clickToSelectValue(currentOption);
    this.extraProps.setCreateFunctionClick({});
  }

  /**
   * 当触发edge聚焦的处理
   * @param e
   */
  onFocusInput(e?: React.FocusEvent<HTMLTextAreaElement>): void {
    const text = this.extraProps.container.aiDescriptionSingle.value.trim();
    const enableToolboxFeature = this.props.store.getState().fot.enableToolboxFeature;
    this.extraProps.isManualRun.current = false;
    const hasApiArr = this.extraProps.container.apiArrSignale.value.length;
    const inputText = e?.target.value || "";

    const isLessThan3Words = !hasApiArr && inputText.split(" ")?.length > 3 ? false : true;
    const visibleCommandPanel = isLessThan3Words || enableToolboxFeature;

    if (!func.isEmpty(text)) {
      this.extraProps.container.onChangeVisibleCommandBar(true, undefined, visibleCommandPanel);
    }
    // this.extraProps.container.showImageGenSelectSingle.value = true;

    if (text.startsWith("/")) {
      //当dropdown显示状态，此时触发聚焦。
      if (this.extraProps.container.showImageGenSelectSingle.value) {
        const aiInput = this.extraProps.container.aiDescriptionInputRef.current;
        if (aiInput) {
          const textArea = get(aiInput, "resizableTextArea.textArea");
          const valueLength = get(textArea, "value.length", 0);
          textArea?.setSelectionRange(valueLength, valueLength);
        }
        return;
      }
      // 正常聚焦，此时是以'/'开头
      const value = text.split("/");
      // 取消聚焦
      this.extraProps.container.aiDescriptionInputRef.current &&
        (this.extraProps.container.aiDescriptionInputRef.current as any).blur();
      // toolbox不拆分
      if (enableToolboxFeature === false) {
        // 拆分/xxxx文本
        this.extraProps.container.searchFuncSignal.value = value[1];
        this.extraProps.container.aiDescriptionSingle.value = "/";
        // 弹出dropdown
      }
      setTimeout(() => {
        this.extraProps.container.apiArrSignale.value = this.getFilterSelectOptions(
          this.extraProps.container.searchFuncSignal.value
        );
        this.extraProps.container.onChangeVisibleCommandBar(true, undefined, visibleCommandPanel);
      }, 100);
    } else if (enableToolboxFeature && text.indexOf("$") > -1) {
      /// blueprint多组合回显
      setTimeout(() => {
        this.extraProps.container.apiArrSignale.value = this.getFilterSelectOptions("");
        this.extraProps.container.onChangeVisibleCommandBar(true, undefined, visibleCommandPanel);
      }, 100);
    }
  }
  focusEdgeOrDropdownEffect = (): void => {
    if (this.extraProps.container.edgeStateSignal.value === "input") {
      // 向上偏移负
      this.extraProps.focusEdgeById(this.extraProps.edgeProps.id, {
        offsetPercent: { y: this.extraProps.container.showImageGenSelectSingle.value ? -0.3 : 1 },
      });
    }
  };
  childSetEdgeLineParam(params: any): void {
    CustomNewEdgeUtil.updateEdgeLineParam(
      this.extraProps.edgeData,
      this.extraProps.edgeProps.id,
      this.creatorNodesStore.setNodes,
      params
    );
  }
  resetAiDescriptionWidth(): void {
    if (isEmpty(this.extraProps.aiDescriptionSpanRef.current)) return;
    const mockSpanWidth = this.extraProps.aiDescriptionSpanRef.current.clientWidth;
    if (mockSpanWidth > INPUT_MAX_WIDTH) {
      this.state.value = this.state.value.copyWith({
        aiDescriptionWidth: mockSpanWidth,
      });
      return;
    }
    if (mockSpanWidth + 30 > INPUT_DEFAULT_WIDTH) {
      this.state.value = this.state.value.copyWith({
        aiDescriptionWidth: mockSpanWidth + 30,
      });
      return;
    }
    if (!!this.extraProps.container.aiDescriptionSingle.value === true) {
      this.state.value = this.state.value.copyWith({
        aiDescriptionWidth: INPUT_DEFAULT_WIDTH,
      });
    }
  }
  closeCustomDropdown(e?: any): void {
    this.setIsGetQuery(true);
    // setHideEditInput(false)
    // this.extraProps.container.showImageGenSelectSingle.value = false
    this.extraProps.container.onChangeVisibleCommandBar(false, e?.target);
  }

  handleLogEvent(inputNodes: any, outputs: any, isManualClick: boolean, updateParam?: UpdateParam): void {
    const selectOption = (this.extraProps.container.apiArrSignale.value as [any]).filter(x => {
      const value = this.extraProps.container.aiDescriptionSingle.value.replace("/", "");
      return x.label === value;
    });
    const isPrompt = selectOption.length === 0;
    const prompt = isPrompt ? this.extraProps.container.aiDescriptionSingle.value : "";
    const functionName = isPrompt ? "" : selectOption[0].label.replace("/", "");
    const type = isPrompt ? "prompt" : selectOption[0].groupTitle || "other";
    const eventProperties = {
      input: inputNodes,
      output: outputs ?? "",
      prompt: prompt,
      function_name: functionName,
      isManualClick: isManualClick,
      type: type,
      prompt_id: !updateParam?.lineParam.identifier ? "" : (updateParam.lineParam.identifier as any).value,
      edge_id: this.extraProps.edgeProps.id,
    };
    logEvent("run_edge", eventProperties);
  }

  // 第一次运行边时显示 Publish 提示框
  showPublishPrompt(): void {
    // 项目已经显示过，不在显示提示框。
    this.extraProps.selectedTemplate =
      this.props.store?.getState()?.studio?.selectedTemplate || this.extraProps.selectedTemplate;
    if (
      func.isEmpty(this.extraProps.selectedTemplate?.v3?.showToolTipStatus) ||
      this.extraProps.selectedTemplate?.v3?.showToolTipStatus === "close"
    )
      return;
    const v3 = Object.assign({}, this.extraProps.selectedTemplate.v3, { showToolTipStatus: "show" });
    const data = Object.assign({}, this.extraProps.selectedTemplate, { v3 });
    this.setSelectedTemplate(data);
  }

  /**
   * 判断是否有权限访问agent
   * 如果需要访问，那么需要加入loading状态
   * @private
   */
  private async handleDoAgentDomo(): Promise<boolean> {
    if (!this.homeStore.state.featureTags.showStudiosAgent) {
      return false;
    }
    this.setEdgeLoading(true);
    return this.doAgentDomo();
  }

  private async doAgentDomo(): Promise<boolean> {
    const isAgent = await this.connectAgentService.setConnectAgentNode({
      selectValueParam:
        this.extraProps.container.aiDescriptionSingle.value || this.extraProps.edgeData.lineParam.enterText,
      nodeText: CustomNewEdgeUtil.getSourceNodeText(this.extraProps.edgeData, this.extraProps.getHomeNodesFunc),
      target: this.extraProps.edgeData.targetNodeId,
      edgeId: this.extraProps.edgeProps.id,
      setEdgeLineParam: this.extraProps.setEdgeLineParam,
    });
    return isAgent === true;
  }

  /**
   * 更新一个值，触发刷新command bar弹窗位置
   */
  updateDropdownAlign(): void {
    if (this.extraProps.container.showImageGenSelectSingle.value) {
      const result = this.state.value.dropdownAlign;
      if (!result || !result.offset) {
        return;
      }
      let x = result.offset[0];
      x = x === 100 ? 101 : 100;
      const newData = Object.assign({ ...result }, { offset: [x, result.offset[1]] });
      this.state.value = this.state.value.copyWith({
        dropdownAlign: newData as DropDownProps["align"],
      });
    }
  }

  /**
   * 当输入框的位置变化了，focus的动画结束触发
   */
  observeCommandBarAnimState(): void {
    if (this.extraProps.container.commandBarController) {
      // 监听再每次开始前都会情况一次，所以需要每次都订阅
      this.extraProps.container.commandBarController.observer((result: any) => {
        if (result.type === "finished") {
          this.updateDropdownAlign();
        }
      });
    }
  }

  /**
   * edge 是否需要隐藏
   *  查找当前edge 的source node 是否还在，如果都不在 需要隐藏当前edge
   */
  edgeHidden(): boolean {
    const nodes = this.extraProps.getHomeNodesFunc();
    const currentEdgeData = nodes.filter(node => node.id === this.extraProps.edgeProps.id)[0];
    const data = currentEdgeData?.data as StudioProjectAttributesV2EdgeData;
    if (data?.flows) {
      const currentEdgeFlows = data.flows as { sourceNodeId: string }[];
      const findSource = nodes.find(node => currentEdgeFlows.find(flow => flow.sourceNodeId === node.id));
      return !findSource;
    }
    return false;
  }
}
