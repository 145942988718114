import { Location as ReactLocation } from "react-router-dom";

export class FotNavigateUtil {
  /**
   * 用来替换字符串中 : 开头的变量值
   *
   * @example
   * replacePlaceholders("function-normal/:id", { id: 'abc1' })
   * // function-normal/abc1
   * @param template
   * @param params
   * @returns
   */
  static replacePlaceholders(template: string, params: Record<string, string>): string {
    let result = template;

    for (const key in params) {
      result = result.replace(`:${key}`, params[key]);
    }

    return result;
  }

  /**
   * 是否是 /editor/function-normal/:id 页面
   */
  static isFunctionNormalPage(pathname: string): boolean {
    return pathname.startsWith("/editor/function-normal/");
  }

  /**
   * 该方法暂时用来判断
   */
  static isNotEditorLayout(location: ReactLocation): boolean {
    return this.isFunctionNormalPage(location.pathname);
  }
}
