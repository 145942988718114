import { RelationSetDestCodeData } from "imagica-corekit/dist/base/api/graphqlTyped/RelationSetDest";
import { ReactNode } from "react";

export interface DefaultProps {
  children?: ReactNode;
}

export enum ApiStatuses {
  Idle,
  Pending,
  Fulfilled,
  Rejected,
}

export interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
}

export interface FunctionModel {
  id: string;
  uuid: string | null;
  attrIndexer: string | null;
  name: string;
  label: string;
  attributes: string; // JSON
  createdAt: string;
  updatedAt: string;
  attributesV2: {
    function_name: string;
    [key: string]: any;
  };
  user: UserModel | null;
  relationId?: string;
  value?: string;
  description?: string;
  codeData?: RelationSetDestCodeData;
}

export interface TemplateModel {
  description: string;
  project_id: number;
  thumbnail: string;
  title: string;
}

export interface QuickStartModel {
  project_id: number;
  video: string;
  project_name: string;
}

export interface ProjectModel {
  id: string;
  uuid: string;
  attrIndexer: string;
  name: string;
  attributes: string; // JSON
  relationId: string;
  createdAt: string;
  updatedAt: string;
  attributesV2: {
    name: string;
    description: string;
    template_name: string;
    thumbnail_s3ObjectKey?: string;
    [key: string]: any;
  };
  v3?: Record<string, any>;
  user: UserModel;

  thumbnailUrl?: string;
}

export interface TutorialModel {
  auto_imagica: boolean;
  name: string;
  thumbnail: string;
  video_url: string;
  description?: string;
  project_id?: number;
}

export enum NotificationTypes {
  Error,
  Success,
}

export interface Notification {
  type: NotificationTypes;
  message: string;
  description: string;
}

export interface UploadFileToS3Args {
  file: File;
}

export interface S3Result {
  s3_url: string;
  s3_s3_url: string;
  s3_https_url: string;
  object_key: string;
}

export interface S3Response {
  data: S3Result;
}

export interface UploadFileToS3Result {
  s3: S3Result;
}
