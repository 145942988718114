import { FotUIDependenciesInterface } from "@uikit/infra/FotUIDependenciesInterface";
import { MyModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { FotNavigateUtil } from "@uikit/util/FotNavigateUtil";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { Location as ReactLocation, NavigateFunction } from "react-router-dom";
class Dep {
  navigate?: NavigateFunction;
  hisotry?: History;
  location?: ReactLocation;
}

/**
 * fot 路由跳转控制类
 *
 * 1. 该类主要为了解决 fot 中用来跳转 router 时,无需关注 是否在 hook(组件环境),在全局环境也能跳转
 * 2. 也为了后期跳转路由做统一处理
 * 3. 同时为其他 fot 的 service 或 class 提供 navigate 方法
 *
 * TODO: 移动到 uikit/cases/fotNavigate 中去当做基类
 */
export class FotNavigateController implements FotUIDependenciesInterface<Dep> {
  dependencies: Dep = new Dep();

  constructor(public showNavPageStore: ShowNavPageStore, public myModificationDataStroe: MyModificationDataStroe) {}

  get navigate(): NavigateFunction {
    if (!this.dependencies.navigate) {
      throw new Error("Please call setDependencies set navigate in UI");
    }

    return this.dependencies.navigate;
  }
  get hisotry(): History {
    if (!this.dependencies.hisotry) {
      throw new Error("Please call setDependencies set hisotry in UI");
    }
    return this.dependencies.hisotry;
  }
  get location(): ReactLocation<any> {
    if (!this.dependencies.location) {
      throw new Error("Please call setDependencies set location in UI");
    }
    return this.dependencies.location;
  }
  setDependencies(value: Dep): void {
    Object.assign(this.dependencies, value);
  }

  /**
   * TODO: 同一个路由地址用 navigate 会重复调用, 需要增加一个可以屏蔽重复调用的逻辑
   * @param route
   * @param params
   */
  gotoRoute<T extends Record<string, any>>(route: string, params?: T): void {
    const pathname = params ? FotNavigateUtil.replacePlaceholders(route, params) : route;

    this.navigate(pathname);
  }

  /**
   * 打开创作者首页(有项目列表,函数列表的页面)
   * TODO: 将代码中 navigate("/editor") 替换为该方法
   */
  openCreatorHomePage(): void {
    this.navigate("/editor");

    // 关闭 creator 组件
    this.showNavPageStore.setShowNavPage(true);

    if (this.myModificationDataStroe.state.showMyModification) {
      // 关闭 function 组件
      this.myModificationDataStroe.setShowMyModification(false);
    }
  }
}
