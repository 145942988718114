import { Container } from "inversify";
import { RegisterUIFunctional } from "./RegisterUIFunctional";
import { RegisterFotProcess } from "./RegisterFotProcess";
import { RegisterBasic } from "./RegisterBasic";
import { RegisterEdgeRun } from "./RegisterEdgeRun";
import { RegisterCreator } from "./RegisterCreator";
import { RegisterPreviewShare } from "./RegisterCreatorPreview";
import { RegisterSave } from "./RegisterSave";
import { RegisterAfter } from "./RegisterAfter";
import { RegisterFotNavigate } from "./RegisterFotNavigate";

/**
 * fot class 注册类
 * 按照从上往下的顺序注册
 *
 * -    RegisterBasic(基础类)
 * - -> RegisterUIFunctional(ui 类)
 * - -> RegisterPreviewShare 预览或分享
 * - -> RegisterFotProcess 页面加载
 * - -> RegisterCreator 创作者
 * - -> RegisterEdgeRun 运行边相关，runall runedge 。。。
 *
 * **顺序目前不能更改，可以增加，但上面列出的几个注册类的顺序不能更改**
 */
export class FotRegistry {
  static configure(container: Container): void {
    RegisterBasic.configure(container);

    RegisterUIFunctional.configure(container);

    RegisterPreviewShare.configure(container);
    RegisterSave.configure(container);

    RegisterFotNavigate.configure(container);
    RegisterFotProcess.configure(container);

    RegisterEdgeRun.preConfigure(container);
    RegisterCreator.configure(container);
    RegisterEdgeRun.configure(container);

    // 最后面的注册
    RegisterAfter.configure(container);
  }
}
