import { useState, useEffect, useImperativeHandle, useRef, forwardRef, useCallback } from "react";
import { useSelector, useDispatch, useStore as useReduxStore } from "react-redux";
import { useStore as useImagicaStore } from "imagica-uikit/dist/hooks/useStore";
import { LoadingOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Menu, Modal, Input, Form, Spin, Button } from "antd";
import func from "@uikit/func";
import style from "./css/HomeNavBar.module.css";
import { studioActions } from "../../store/studio";
import { fotActions } from "../../store/fot";
import { getMidjourney } from "./nodeTypeComponents/nodeTypeDispose";
import { editorActions } from "../../store/editor";
import { cloneDeep, isEmpty, isString } from "lodash";
import cls from "classnames";
import { useNavigate } from "react-router-dom";
import { logEvent } from "@uikit/service/amplitude";
import BackHomeModal from "./BackHomeModal";
import { DEFALUT_PROJECT_NAME, PROJECT_NAME_LENGTH } from "../thinking-layout-editor/constants";
import { settings } from "imagica-corekit/dist/base/kernel/Settings";
import Feedback, { MenuItemValues } from "./Feedback";
import { getAssetsIcon } from "./utilities";
import UserAvatarV2 from "@views/components/UserAvatarV2";
import LogoButton from "@views/components/LogoButton";
import { useMemo } from "react";
import { getIt } from "@uikit/getIt";
import { UiFunctionService } from "@uikit/service/UiFunctionService";
import { eventbus } from "imagica-corekit/dist/base/cutil/Eventbus";
import { CreateFunctionPageBackMsg } from "@uikit/msg/CreateFunctionPageBackMsg";
import { UpgradeButton } from "./Upgrade/UpgradeButton";
import { ProjectModifiedUtil } from "@uikit/projectModified/ProjectModifiedUtil";
import { ProjectNetDataStore } from "@base/ProjectNetDataStore";
import { HomeStore } from "imagica-corekit/dist/cases/store/HomeStore";
import { useStore } from "imagica-uikit/dist/hooks/useStore";
import { myModificationDataStroe } from "@uikit/store/MyModificationDataStroe";
import { projectPageStore } from "@uikit/store/ProjectPageStore";
import { ShowNavPageStore } from "@uiview/views/HomeRoot/store/ShowNavPageStore";
import { previewStore } from "@uiview/store/PreviewStore";
import { popupFeedbackStore } from "@uikit/store/PopupFeedback";
import { ProjectRunStore } from "@uikit/store/ProjectRunStore";
import { PublishV2Item } from "@uiview/views/HomeNavBarViews/PublishV2/PublishV2Item";
import { CopyLinkItem } from "@uiview/views/HomeNavBarViews/CopyLink/CopyLink";
import { ChatOnboardingStore } from "imagica-corekit/dist/cases/chatOnboarding/ChatOnboarding";
import { newOnboardingFlowStore } from "@uikit/store/NewOnboardingFlowStore";
import { PreferencesStore } from "imagica-corekit/dist/base/store/PreferencesStore";
import { NavBarStore } from "@uiview/views/navBar/NavBarStore";
import { ObjectRelationGqlService } from "@uikit/service/ObjectRelationGqlService";
import { CreatorSaasAppStore } from "@uikit/store/CreatorSaasAppStore";
import { CreateService } from "@uikit/service/CreateService";
import { CreatorNodesStore } from "@uikit/store/CreatorNodesStore";
import { CreatorEdgesStore } from "@uikit/store/CreatorEdgesStore";
import { FotSaveService } from "@uikit/service/FotSaveService";
import { FotProcessManager } from "@uikit/manager/FotProcessManager";
import { CreateProjectService } from "@uikit/service/CreateProjectService";
import { PreviewAppStore } from "@uikit/store/PreviewAppStore";
import { HomeMethodsService } from "@uikit/service/HomeMethodsService";
import { CanvasDataRef } from "@uikit/model/CanvasDataRef";
import { EdgeRunAll } from "@uikit/service/EdgeRunAll";
import { CotStore } from "@uikit/store/CotStore";
import { ProjectFunctionStore } from "@uikit/store/ProjectFunctionStore";
import { CreateNewProjectService } from "@uiview/views/ProjectCanvas/CreateNewProject";
import { FotNavigateController } from "@uikit/service/FotNavigateController";

/**
 * 顶部项目名称组件
 */
const CurrentProject = forwardRef((props, ref) => {
  const { clickItemState, outerState, onSave } = props;
  const myModificationDataState = useStore(myModificationDataStroe).value;
  const showNavPageStore = getIt(ShowNavPageStore);
  const { saveLoading, selectedTemplate, createJSFuncData, createAPIFuncData, isFunc } = outerState;
  const { clickItemButton } = clickItemState;

  const [innerState, setInnerState] = useState({
    showInput: false,
    inputValue: "",
    prevInputValue: "",
  });

  useImperativeHandle(ref, () => ({
    getInnerState() {
      return innerState;
    },
    triggerInput(show = false) {
      setInnerState(prev => ({
        ...prev,
        showInput: show,
      }));
    },
    resetInnerState() {
      setInnerState(() => ({
        showInput: false,
        inputValue: "",
        prevInputValue: "",
      }));
    },
  }));

  const emptyProejctText = "No Project Selected";

  const { showInput, inputValue, prevInputValue } = innerState;
  const disabledJSFunc = createJSFuncData.loading || createJSFuncData.state === "new";
  const disabledAPIFunc = createAPIFuncData.loading || createAPIFuncData.state === "new";
  const disabledWithFunc = isFunc ? disabledJSFunc || disabledAPIFunc : isEmpty(selectedTemplate);
  const disabled = saveLoading || disabledWithFunc || props.disabled;

  useEffect(() => {
    const ProjectName = isFunc
      ? createAPIFuncData.parameter.name || createJSFuncData.parameter.name
      : selectedTemplate.template_name || "";

    setInnerState(prev => ({ ...prev, prevInputValue: ProjectName, inputValue: ProjectName }));
  }, [createAPIFuncData, createJSFuncData, selectedTemplate]);

  const _onDbclick = () => {
    // 1. disabledJSFunc edit
    if (showNavPageStore.state.showNavPage && disabledJSFunc) {
      return;
    }

    // 2. disabledAPIFunc edit
    if (showNavPageStore.state.showNavPage && disabledAPIFunc) {
      return;
    }

    if (showInput) {
      return;
    }
    setInnerState(prev => ({
      ...prev,
      showInput: true,
    }));
  };

  const checkValue = useCallback(value => {
    return isString(value) ? value.slice(0, PROJECT_NAME_LENGTH.max) : "";
  }, []);

  const _onChange = ({ target: { value } }) => {
    const inputValue = checkValue(value);
    setInnerState(prev => ({
      ...prev,
      inputValue,
    }));
  };

  const _onSave = () => {
    if (isFunc) {
      setInnerState(prev => ({
        ...prev,
        showInput: false,
      }));
      return;
    }
    // 如果修改名相同则跳过
    if (prevInputValue.trim() === inputValue.trim()) {
      setInnerState(prev => ({
        ...prev,
        showInput: false,
        inputValue: checkValue(prev.prevInputValue),
      }));
      return;
    }
    onSave();
  };

  return (
    <div className={style.base_content}>
      <div
        className={`${style["block-box"]} ${style["text-box"]} ${style["hover-box"]}
        ${clickItemButton ? "select-text" : ""} ${disabled ? "not-optional" : ""} `}
        // ${fetching || !selectedTemplate.template_name ? "not-optional" : ""}
        // onClick={() => setClickItemButton(true)}
        onDoubleClick={_onDbclick}
      >
        {/* {!clickItemButton ? (
        <img src={textIcon}></img>
      ) : (
        <img src={`${settings.S3CDN}${settings.viewAssetsPath}black-text-Icon.png`}></img>
      )} */}
        {showInput ? (
          <span className={style["icon-text-center"]}>
            <Input
              autoFocus
              disabled={disabled}
              value={inputValue}
              maxLength={PROJECT_NAME_LENGTH.max}
              onChange={_onChange}
              onPressEnter={_onSave}
              onBlur={_onSave}
              onKeyDown={e => {
                e.stopPropagation();
              }}
            />
          </span>
        ) : (
          <span className={`${style["icon-text-center"]} ${disabled ? style["text-grey"] : ""}`}>
            <span>
              {inputValue || emptyProejctText}
              {!isFunc && !myModificationDataState.showMyModification ? null : " [function]"}
            </span>
          </span>
        )}
      </div>
    </div>
  );
});

const DividerItem = () => {
  return (
    <div className={style["space_line"]}>
      <div className={style["division"]}></div>
    </div>
  );
};

const RunAll = () => {
  return <img src={`${settings.S3CDN}${settings.viewAssetsPath}run-all-Edit.png`}></img>;
};

const RunAllTop = props => {
  const cotStore = getIt(CotStore);
  const cotStoreState = useStore(cotStore).value;
  const fetching = useSelector(state => state.fot.fetching);
  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);
  const projectRunStore = getIt(ProjectRunStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const projectRunStoreState = useImagicaStore(projectRunStore).value;
  const showNavPageState = useImagicaStore(showNavPageStore).value;

  const inCotCreating = cotStoreState.showCot || cotStoreState.oldCotAnimationState.creating === true;

  return (
    <div className={style["divider-box"]}>
      <DividerItem />
      <div
        className={`${style["block-box"]} ${
          fetching || !selectedTemplate.name || showNavPageState.showNavPage ? "not-optional" : ""
        }`}
      >
        <Button
          className={style["run-all-box"]}
          icon={<RunAll />}
          // bsf-5585
          onMouseDown={props.onMouseDown}
          loading={projectRunStoreState.runAllLoading}
          type="primary"
          shape="round"
          disabled={!selectedTemplate.name || showNavPageState.showNavPage || inCotCreating}
        >
          Run All
        </Button>
      </div>
    </div>
  );
};
const creatorSaasAppStore = getIt(CreatorSaasAppStore);

/**
 *
 * @param {any} props
 */
const HomeNavBar = props => {
  const dataVersion = "v3";
  const store = useReduxStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gql = getIt(ObjectRelationGqlService);
  const createService = getIt(CreateService);
  const createProjectService = getIt(CreateProjectService);
  const homeStore = getIt(HomeStore);
  const preferencesStore = getIt(PreferencesStore);
  const homeStoreState = useStore(homeStore).value;
  const myModificationDataState = useStore(myModificationDataStroe).value;
  const chatOnboardingStore = getIt(ChatOnboardingStore);
  const chatOBState = useImagicaStore(chatOnboardingStore).value;
  const creatorNodesStore = getIt(CreatorNodesStore);
  const creatorEdgesStore = getIt(CreatorEdgesStore);
  const canvasDataRef = getIt(CanvasDataRef);
  const edgeRunAll = getIt(EdgeRunAll);
  const cotStore = getIt(CotStore);
  const fotNavigateController = getIt(FotNavigateController);
  const cotStoreState = useStore(cotStore).value;
  const createNewProjectService = getIt(CreateNewProjectService);
  const projectFunctionStore = getIt(ProjectFunctionStore);
  const fotProcessManager = FotProcessManager.use();
  const featureTags = homeStoreState.featureTags;
  const navBarStore = getIt(NavBarStore);
  const showNavPageStore = getIt(ShowNavPageStore);
  const navBarState = useImagicaStore(navBarStore).value;
  //FIXME: NavBarStore
  const [isNewCustomFunctionOpen, setIsNewCustomFunctionOpen] = useState(false);
  //FIXME: NavBarStore
  const [customFunctionTitle, setCustomFunctionTitle] = useState("");

  const showNavPageState = useImagicaStore(showNavPageStore).value;
  const fotSaveService = getIt(FotSaveService);

  const homeMethods = getIt(HomeMethodsService);
  const previewAppStore = getIt(PreviewAppStore);
  //FIXME 放在creatorRefStore
  const currentProjectRef = useRef();
  // store states
  const userMe = useSelector(state => state.editor.me);

  const createJSFuncData = useSelector(state => state.editor.createJSFuncData);
  const setCreateJSFuncData = val => {
    dispatch(editorActions.setCreateJSFuncData(val));
  };

  const createAPIFuncData = useSelector(state => state.editor.createAPIFuncData);
  const setCreateAPIFuncData = val => {
    dispatch(editorActions.setCreateAPIFuncData(val));
  };

  const isFunc = createAPIFuncData?.open || createJSFuncData?.open;
  const isFuncLoading = createAPIFuncData?.loading || createJSFuncData?.loading;
  const setEnableCOTStartAgain = val => {
    dispatch(editorActions.setEnableCOTStartAgain(val));
  };
  const developerMode = useSelector(state => state.fot.developerMode);
  const enableExperimentalFeature = useSelector(state => state.fot.enableExperimentalFeature);
  const setDeveloperMode = val => {
    dispatch(fotActions.setDeveloperMode(val));
  };

  const fetching = useSelector(state => state.fot.fetching);
  const mode = useSelector(state => state.fot.mode);

  const selectedTemplate = useSelector(state => state.studio.selectedTemplate);

  const setSelectedTemplate = val => {
    dispatch(studioActions.setSelectedTemplate(val));
  };

  const setLoadingState = val => {
    dispatch(studioActions.setLoadingState(val));
  };

  const setPrompt = val => {
    dispatch(studioActions.setPrompt(val));
  };

  const updateSelectedTemplate = useSelector(state => state.studio.updateSelectedTemplate);

  const processingCOT = useSelector(state => state.editor.processingCOT);
  const setImageGenSelectOptions = val => {
    dispatch(fotActions.setImageGenSelectOptions(val));
  };

  const saveAsExistingTemplate = useSelector(state => state.studio.saveAsExistingTemplate);

  const backHomeModal = useSelector(state => state.editor.backHomeModal);
  const setBackHomeModal = val => {
    dispatch(editorActions.setBackHomeModal(val));
  };
  const setShowTooltip = val => {
    dispatch(editorActions.setShowTooltip(val));
  };
  const setCloseToolTipArr = val => {
    dispatch(editorActions.setCloseToolTipArr(val));
  };

  const setStudioProjectName = val => {
    dispatch(fotActions.setStudioProjectName(val));
  };
  const setDisableAddPreview = val => {
    dispatch(fotActions.setDisableAddPreview(val));
  };

  //FIXME 放在navBarStore
  const [clickItemButton, setClickItemButton] = useState(false);

  const [, setNewTemplateObj] = useState({ name: "", domain: "" });
  //FIXME NavBarStore
  const [newFunctionData, setNewFunctionData] = useState({ name: "", description: "" });
  const [, setSelectedExistingProject] = useState({});
  const [, setDisableExistingProjectModalOK] = useState(false);
  const [showPublishFunctionConfirm, setShowPublishFunctionConfirm] = useState(false);
  const [publishFunctionLoading, setPublishFunctionLoading] = useState(false);
  const [publishFunctionName, setPublishFunctionName] = useState("");

  const showExperimentalFeaturesToggle = (userMe?.feature_tags ?? []).every(x => x !== "disable_studios_exp_features");
  const showStudiosDevMode = (userMe?.feature_tags ?? []).every(x => x !== "disable_studios_dev_mode");

  // const { show: showShareProjectTip } = useShareProjectTip()
  // methods

  const closePromptHide = () => {
    setPromptStatus("close");
    newOnboardingFlowStore.setSelectedFlowItem({
      ...newOnboardingFlowStore.state.selectedFlowItem,
      showTooltip: false,
    });
  };

  const setPromptStatus = status => {
    const selectedTemplate = store.getState().studio.selectedTemplate;
    const v = Object.assign({}, selectedTemplate[dataVersion], { showToolTipStatus: status });
    const data = Object.assign({}, selectedTemplate, { [dataVersion]: v });
    setSelectedTemplate(data);
  };

  const handleFunctionData = () => {
    setNewFunctionData({ name: "", description: "" });
    setCustomFunctionTitle("");
    setIsNewCustomFunctionOpen(false);
    setCreateJSFuncData({ loading: false });
    setCreateAPIFuncData({ loading: false });
  };

  const handleSaveFunctionEventProperties = (source, newFunctionData, createJSFuncData, createAPIFuncData) => {
    createJSFuncData = store.getState().editor.createJSFuncData;
    createAPIFuncData = store.getState().editor.createAPIFuncData;
    let functionType = createJSFuncData.open ? "js" : "api";
    let is_first_created = false;
    if (createJSFuncData.open) {
      //js
      is_first_created = createJSFuncData.state == "new" || false;
    } else {
      //api
      is_first_created = createAPIFuncData.state == "new" || false;
    }
    const eventProperties = {
      name: newFunctionData.name,
      description: newFunctionData.description,
      type: functionType,
      is_first_created: is_first_created,
      source: source,
    };
    return eventProperties;
  };

  const handleFunctionDataOk = () => {
    if (func.isEmpty(newFunctionData.name)) return;
    const eventProperties = handleSaveFunctionEventProperties(newFunctionData, createJSFuncData, createAPIFuncData);
    if (createJSFuncData.open) {
      setCreateJSFuncData({ loading: true, parameter: newFunctionData, function: "handleOk" });
      logEvent("save_function", eventProperties);
    }
    if (createAPIFuncData.open) {
      setCreateAPIFuncData({ loading: true, parameter: newFunctionData, function: "handleOk" });
      logEvent("save_function", eventProperties);
    }
  };

  const newFunctionDataChange = (e, key) => {
    const data = Object.assign({}, { ...newFunctionData }, { [key]: e.target.value });
    setNewFunctionData(data);
  };

  const handleSaveAsExistingTemplate = () => {
    createTemplate(selectedTemplate.name || DEFALUT_PROJECT_NAME);
  };

  const getTemplateByName = async (id, updateSelected = false) => {
    try {
      dispatch(fotActions.setFetching(true));
      setLoadingState({ existingProjectModalOK: true });
      const res = await gql.objects({ id });
      if (func.isEmpty(res?.[0]?.attributes)) {
        throw "Get template failed";
      }
      const data = {
        id: res[0].id,
        name: res[0].attributesV2.template_name,
        ...res[0].attributesV2,
      };
      if (updateSelected) {
        setSelectedTemplate(data);
        const promptText = data.prompts && data.prompts[0] && data.prompts[0].text;
        setPrompt(promptText || "");
      } else {
        setSelectedExistingProject(data);
      }
      setDisableExistingProjectModalOK(false);
    } catch (error) {
      func.messageError(error);
      setSelectedExistingProject({});
      setDisableExistingProjectModalOK(true);
    } finally {
      dispatch(fotActions.setFetching(false));
      setLoadingState({ existingProjectModalOK: false });
    }
  };

  const createTemplate = async templateName => {
    if (!userMe.objectId) {
      func.messageUtil("unable to get me id");
      return true;
    }
    dispatch(fotActions.setFetching(true));
    setLoadingState({ promptSaveBtn: true, promptNewBtn: true, promptCard: true });
    const params = {
      // 情况项目名两边多余空格
      template_name: templateName.trim(),
      description: newFunctionData.description,
      [dataVersion]: {
        edges: [],
        nodes: [],
        nodeIndex: 0,
        edgeIndex: 0,
      },
    };
    const imagicaStudioTutorial = await preferencesStore.getByKey("imagica_studio_tutorial");
    if (typeof createProjectService.initNewProjectV2 === "function" && imagicaStudioTutorial?.viewed) {
      // 不要使用save接口初始化project， 改为在调用create时就初始化第一个node
      const initNodes = createProjectService.initNewProjectV2();
      const newNodes = initNodes.map(x => {
        const nodeObj = canvasDataRef.getSimpleNodeData(x);
        return Object.assign({}, { ...x }, { data: nodeObj });
      });
      params[dataVersion].nodes = newNodes;
    }
    try {
      const selectedTemplateObj = await createService.createProject(params);
      selectedTemplateObj.name = selectedTemplateObj.name || templateName;
      setSelectedTemplate(selectedTemplateObj || {});
      setStudioProjectName(selectedTemplateObj.name);
      navigate(`/editor/${selectedTemplateObj.id}`);
      setNewTemplateObj({ name: "", domain: "" });
    } catch (error) {
      console.error(error);
      func.messageUtil(`Failed to create project ${error}`);
    } finally {
      dispatch(fotActions.setFetching(false));
      setLoadingState({ promptSaveBtn: false, promptNewBtn: false, promptCard: false });
    }
  };

  const SaveLabel = () => {
    return (
      <>
        <span>Save</span>
        <span style={{ marginLeft: "5px" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
            spinning={homeStoreState.homeNavSaveLoading}
          />
        </span>
      </>
    );
  };

  const checkerProjectName = async () => {
    const { showInput, inputValue, prevInputValue } = currentProjectRef.current?.getInnerState();

    if (showInput && !inputValue.trim()) {
      let msg = props.page === "home" ? "Please enter the function name" : "Please enter the project name";
      func.messageUtil(`${msg}`);
      return Promise.reject();
    }

    // 去掉项目名两边空格
    return {
      newName: inputValue.trim(),
      isModified: inputValue.trim() !== prevInputValue.trim(),
    };
  };

  // saveProject createJSFuncData.open 处直接更新,跳过了弹窗,监听 `createJSFuncData` 变化 隐藏输入框
  useEffect(() => {
    if (!createJSFuncData.loading) {
      currentProjectRef.current?.triggerInput(false);
    }
  }, [createJSFuncData]);
  useEffect(() => {
    if (!createAPIFuncData.loading) {
      currentProjectRef.current?.triggerInput(false);
    }
  }, [createAPIFuncData]);

  const createJSFunction = () => {
    const jsTitleData = {
      modify: "Modify Custom JS Function",
      new: "New Custom JS Function",
    };
    setCustomFunctionTitle(jsTitleData[createJSFuncData.state]);
    if (!func.isEmpty(createJSFuncData.parameter)) {
      setNewFunctionData(createJSFuncData.parameter);
    }
    if (createJSFuncData.state === "new") {
      setNewFunctionData({ name: "", description: "" });
    }
    setIsNewCustomFunctionOpen(true);
  };
  const updateJSFunction = (newProjectName, source) => {
    setCreateJSFuncData({
      loading: false,
      parameter: {
        ...newFunctionData,
        name: newProjectName,
      },
      function: "handleOk", // custom js function 组件会监听这个 function 字段改变，执行相关代码
    });
    const updatedFunctionData = {
      ...newFunctionData,
      name: newProjectName,
    };
    const eventProperties = handleSaveFunctionEventProperties(source, updatedFunctionData, createJSFuncData, "");
    logEvent("save_function", eventProperties);
  };
  const createAPIFunction = () => {
    const apiTitleData = {
      modify: "Modify Custom API Function",
      new: "New Custom API Function",
    };
    setCustomFunctionTitle(apiTitleData[createAPIFuncData.state]);
    if (!func.isEmpty(createAPIFuncData.parameter)) {
      setNewFunctionData(createAPIFuncData.parameter);
    }
    if (createAPIFuncData.state === "new") {
      setNewFunctionData({ name: "", description: "" });
    }
    setIsNewCustomFunctionOpen(true);
  };
  const updateAPIFunction = (newProjectName, source) => {
    setCreateAPIFuncData({
      loading: false,
      parameter: {
        ...createAPIFuncData,
        name: newProjectName,
      },
      function: "handleOk", // custom api function 组件会监听这个 function 字段改变，执行相关代码
    });
    const updatedFunctionData = {
      ...newFunctionData,
      name: newProjectName,
    };
    const eventProperties = handleSaveFunctionEventProperties(source, updatedFunctionData, "", createAPIFuncData);
    logEvent("save_function", eventProperties);
  };
  /**
   * @todo 当保存改项目时，点击首页返回，currentProject 名字未变
   * @returns
   */
  //FIXME: 留在父组件，需要用到的地方通过props传入
  const saveProject = async source => {
    setEnableCOTStartAgain(null);
    // 校验保存的项目名
    let newProjectName;
    let isNameModified;
    try {
      const { newName, isModified } = await checkerProjectName();
      newProjectName = newName;
      isNameModified = isModified;
    } catch {
      return;
    }

    if (homeStoreState.homeNavSaveLoading) return;

    // cjs function保存
    if (createJSFuncData.open) {
      if (createJSFuncData.state !== "new") {
        // 跳过弹窗, 直接更新
        updateJSFunction(newProjectName, source);
      } else {
        createJSFunction();
      }
      return;
    }
    // capi function保存
    if (createAPIFuncData.open) {
      if (createAPIFuncData.state !== "new") {
        // 跳过弹窗, 直接更新
        updateAPIFunction(newProjectName, source);
      } else {
        createAPIFunction();
      }
      return;
    }

    try {
      homeStore.setHomeNavSaveLoading(true);
      // 保存项目
      if (typeof fotSaveService.handleSave !== "function") return;
      const selectedTemplate = store.getState().studio.selectedTemplate;
      // 直接修改项目名保存
      if (!func.isEmpty(newProjectName) && newProjectName !== DEFALUT_PROJECT_NAME && isNameModified) {
        // 保存新的项目并替换新的项目名
        const newTemplateObj = cloneDeep(selectedTemplate);
        newTemplateObj.name = newProjectName;
        newTemplateObj.template_name = newProjectName;
        await fotSaveService.handleSave({
          templateObj: newTemplateObj,
          source: source,
        });
        currentProjectRef.current?.triggerInput(false);
      } else {
        // 点击save按钮保存
        await fotSaveService.handleSave({
          source: source,
        });
      }
      // 更新本地保存的function数据
      if (myModificationDataState.showMyModification) {
        // function数据中有个 originElement 属性后期需要可以更新
        projectFunctionStore.updateFunctionById(myModificationDataState.myModificationData.id, {
          isV3Function: true,
          nodeArr: creatorNodesStore.getNodes(),
          edgeArr: creatorEdgesStore.getEdges(),
        });
      }
    } catch (error) {
      func.messageError(error);
    } finally {
      homeStore.setHomeNavSaveLoading(false);
    }
  };

  const handleProjectTitleSave = () => {
    saveProject("project title");
  };

  const clickCurrentProject = () => {
    if (func.isEmpty(selectedTemplate.template_name)) {
      getIt(FotNavigateController).openCreatorHomePage();
    } else {
      showNavPageStore.setShowNavPage(false);
    }
  };

  // const CurrentProject = () => {
  //   return (
  //     <div className={`${style['block-box']} ${style['text-box']} ${style['hover-box']} ${clickItemButton ? 'select-text' : ''} ${fetching || !selectedTemplate.template_name ? 'not-optional' : ''}`} onClick={() => setClickItemButton(true)}>
  //       {
  //         !clickItemButton
  //         ? <img src={textIcon}></img>
  //         : <img src={`${settings.S3CDN}${settings.viewAssetsPath}black-text-Icon.png`}></img>
  //       }
  //       {
  //         <span className={style['icon-text']}>
  //           <span>{isFunc ? createAPIFuncData.parameter.name || createJSFuncData.parameter.name : selectedTemplate.template_name || 'No Project Selected'}
  //           {
  //             !isFunc && !myModificationDataState.showMyModification ? null : ' [function]'
  //           }
  //           </span>
  //           {
  //             selectedTemplate.domain ?
  //               <span><Tag color="blue">{selectedTemplate.domain}</Tag></span>
  //             : ''
  //           }
  //         </span>
  //       }
  //     </div>
  //   )
  // }

  // to be added back in later when multiple tabs supported
  // const AddProjects = () => {
  //   return (
  //     <div className={style['divider-box']}>
  //       <div className={style['block-box']}>
  //         <img src={`${settings.S3CDN}${settings.viewAssetsPath}add-Icon.png`}></img>
  //       </div>
  //       <DividerItem />
  //     </div>
  //   )
  // }

  const SaveProjects = () => {
    const disabled =
      fetching ||
      ((!selectedTemplate.name || showNavPageState.showNavPage) &&
        !myModificationDataState.showMyModification &&
        !isFunc);
    return (
      // bsf-5585
      <div className={style.base_content} onMouseDown={() => saveProject("save button")}>
        <div
          data-testid="HomeNavBarSave"
          className={`
          ${style["block-box"]}
          ${style["text-box"]}
          ${style["saveBtn"]}
          ${
            !isFunc &&
            (fetching ||
              ((!selectedTemplate.name || showNavPageState.showNavPage) && !myModificationDataState.showMyModification))
              ? "not-optional"
              : "select-text"
          }
        `}
        >
          {disabled ? (
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}text-Icon.png`}></img>
          ) : (
            <img src={`${settings.S3CDN}${settings.viewAssetsPath}black-text-Icon.png`}></img>
          )}
          <span className={`${disabled ? style["text-grey"] : ""} ${style["icon-text"]}`}>
            <SaveLabel color="red" />
          </span>
        </div>
      </div>
    );
  };

  const moveRightNodeToCenter = () => {
    // const widthZoom = (window.innerWidth - tipWidth - tipLeftDistance * 2) / window.innerWidth
    // const heightZoom = (window.innerHeight - 100 - 110) / window.innerHeight
    // const zoom = Math.min(widthZoom, heightZoom)
    // const blankWidth = (tipWidth + tipLeftDistance * 2) / zoom
    // const blankY = 100 / zoom
    // const blankHeight = (window.innerHeight - 100 - 110) / zoom
    const nodes = creatorNodesStore.getNodes();
    const firstNode = nodes.find(x => x.id === "editor-1");
    const secondNode = nodes.find(x => {
      return x.id === "editor-3" || x.id === "group-3";
    });
    // 保证类型安全, TypeError: Cannot read properties of undefined (reading 'style')
    if (!firstNode || !secondNode) {
      return;
    }
    const firstNodeWidth = firstNode.style?.width || firstNode.width;
    const firstNodeHeight = firstNode.style?.height || firstNode.height;
    const secondNodeHeight = secondNode.style?.height || secondNode.height;
    const secondNodeX = secondNode.position.x;
    const secondNodeY = secondNode.position.y;
    const edgeLen = 350;
    const firstNodePosition = {
      x: secondNodeX - edgeLen - firstNodeWidth,
      y: secondNodeY + secondNodeHeight / 2 - firstNodeHeight / 2,
    };
    creatorNodesStore.setNodes(l => {
      return l.map(x => {
        if (x.id === firstNode.id) {
          return {
            ...x,
            position: firstNodePosition,
          };
        }
        return x;
      });
    });

    // FIXME: stud-1203
    // if (nodeRenderComplatedState) {
    //   fitBounds(
    //     {
    //       x: firstNodePosition.x,
    //       y: secondNodeY - blankY,
    //       width: firstNodeWidth + secondNodeWidth + edgeLen + blankWidth,
    //       height: secondNodeHeight + blankHeight,
    //     },
    //     {
    //       duration: 1000,
    //     }
    //   );
    // }
  };

  const beforePublish = () => {
    closePromptHide();
    logEvent("click_publish_as");
    setPromptStatus("close");
    setShowTooltip("close");
    setDisableAddPreview(true); // add content状态重置
    setCloseToolTipArr(["editor-2"]);
    if (myModificationDataState.showMyModification || isFunc) {
      console.error(selectedTemplate.template_name);
      const funcData = Object.assign({}, { ...createJSFuncData?.parameter }, { ...createAPIFuncData?.parameter });
      setPublishFunctionName(isFunc ? funcData?.name : selectedTemplate?.template_name);
      setShowPublishFunctionConfirm(true);
      return false;
    }
    return true;
  };

  const Share = () => {
    const inCotCreating = cotStoreState.showCot || cotStoreState.oldCotAnimationState.creating === true;
    return (
      <div className={style["divider-box"]} onMouseDown={generateShareLink}>
        <div
          className={`${style["block-box"]} ${
            fetching || !selectedTemplate.name || showNavPageState.showNavPage ? "not-optional" : ""
          }`}
        >
          <Button
            icon={<ShareAltOutlined />}
            className={style["share-box"]}
            loading={navBarState.shareLoading}
            type="primary"
            shape="round"
            disabled={!selectedTemplate.name || showNavPageState.showNavPage || inCotCreating}
          >
            Share Project
          </Button>
        </div>
      </div>
    );
  };

  const RunButton = () => {
    return (
      <div className={style["round-button-view"]}>
        <Button
          className={style["round-button"]}
          type="primary"
          shape="round"
          loading={createJSFuncData?.loading || createAPIFuncData?.loading}
        >
          Run
        </Button>
      </div>
    );
  };

  const RunFunction = () => {
    if (myModificationDataState.showMyModification) {
      edgeRunAll.clickRunAllBtn();
      return;
    }
    if (createJSFuncData.open) {
      setCreateJSFuncData({ function: "clickRun", loading: true });
    }
    if (createAPIFuncData.open) {
      setCreateAPIFuncData({ function: "clickRun", loading: true });
    }
  };
  const handleReset = () => {
    // 重置unde数据
    closePromptHide();
    props.resetUndoData();
    props.emptyNodes();
    // 重置右侧preview数据
    previewAppStore.resetPreviewData();
    // 重置按钮默认颜色
    setClickItemButton(false);
    // 重置project inputvalue 内容
    currentProjectRef.current?.resetInnerState();

    // 重置Onboarding input
    cotStore.setMultiRoundChatOnboarding(false);

    // 显示首页
    fotNavigateController.openCreatorHomePage();
  };

  const resetCot = () => {
    popupFeedbackStore.setEnableCOTFeedbackPopup(false);
    setBackHomeModal(false);
    projectPageStore.setIsPublish(false);
    handleReset();
    cotStore.setCotAutomaticNode(false);
    setEnableCOTStartAgain(null);
    cotStore.setOldCotAnimationState({
      creating: false,
      inputIds: [],
      currentInputId: "",
    });
  };

  const saveAndExit = async () => {
    closePromptHide();
    // if(createJSFuncData.open) {
    //   setCreateJSFuncData({function: 'onCancel'})
    //   return;
    // }
    // if(createAPIFuncData.open) {
    //   setCreateAPIFuncData({function: 'onCancel'})
    //   return;
    // }
    // function和project都需要保存
    if (!showNavPageStore.state.showNavPage) {
      // 切换到首页保存一次
      await saveProject("home button");
    }
    handleReset();
  };

  const handleFeedbackMenuItemClick = useCallback(
    /** @param {import('./Feedback').MenuItem} menuItem */
    menuItem => {
      if (menuItem.value === MenuItemValues.RequestFeature) {
        window.open("https://imagica.ideas.aha.io/");
      }
      if (menuItem.value === MenuItemValues.ReportBug) {
        document.getElementById("birdeatsbug-default-button")?.click();
      }
    },
    []
  );

  const handleDropdownItemClick = useCallback(
    /** @param {import('../components/UserAvatar').DropdownItem} dropdownItem */
    dropdownItem => {
      if (dropdownItem.key === "myAccount") {
        logEvent("my_account_access");
        props.clickMyAccount();
      }
      if (dropdownItem.key === "logout") {
        fotProcessManager.logout();
      }
    },
    [props]
  );

  const myProjectClassName = useMemo(() => {
    return cls(props.page === "home" && style.menuItemNewProject);
  }, [featureTags, props.page]);

  const hideNewProject = !showNavPageState.showNavPage;
  const hidenCurrentProject = showNavPageState.showNavPage && !isFunc;
  /// 从左侧开始排布的数据
  const items = [
    {
      key: "home",
      label: <LogoButton />,
      disabled: processingCOT === true,
      onClick: () => {
        if (chatOnboardingStore.state.updateTime !== chatOnboardingStore.state.saveTime) {
          return setBackHomeModal(true);
        }
        if (createJSFuncData.open) {
          eventbus.emit(new CreateFunctionPageBackMsg(createJSFuncData.edgeId));
          setEnableCOTStartAgain(null);
          setCreateJSFuncData({ function: "onCancel" });
          return;
        }
        if (createAPIFuncData.open) {
          eventbus.emit(new CreateFunctionPageBackMsg(createAPIFuncData.edgeId));
          setEnableCOTStartAgain(null);
          setCreateAPIFuncData({ function: "onCancel" });
          previewAppStore.clearPreviewApp();
          return;
        }
        // 在首页时不再触发
        // if (showNavPageState.showNavPage) return;
        const smallScreenWidth = 979;
        if (showNavPageState.showNavPage) {
          if (window.innerWidth < smallScreenWidth) {
            showNavPageStore.setShowSmallScreenSideBar(!showNavPageStore.state.isShowSmallScreenSideBar);
          }
          return;
        }
        logEvent("click_home");
        newOnboardingFlowStore.setSelectedFlowItem({});
        // 退出function 编辑时重置 node 和 edge 的index
        homeMethods.setNodeEdgeIndex();
        // 没有改动直接返回首页， 并且当前不是通过cot生成的项目
        // util.isEmpty(props.undoRef.current) && !initWithCot
        if (
          !ProjectModifiedUtil.hasModified({
            withoutCot: true,
            showNavPage: props.showNavPage,
            saasUIData: creatorSaasAppStore.state.saasUIData,
            appList: previewStore.state.previewAppList,
            selectedTemplate: selectedTemplate,
          })
        ) {
          setEnableCOTStartAgain(null);
          setShowTooltip("close");
          handleReset();
          resetCot();
          dispatch(fotActions.setVariableList([]));
          getIt(ProjectNetDataStore).stopAutoUpdate();
          previewAppStore.clearPreviewApp();
          return;
        }
        // 显示确认保存弹窗
        setBackHomeModal(true);
      },
    },
    {
      label: <DividerItem />,
    },
    {
      hide: hideNewProject.toString(),
      key: "new project",
      className: myProjectClassName,
      label: (
        <div className={style.base_content}>
          <div
            className={cls(
              style["block-box"],
              style["text-box"]
              /* style["hover-box"] */
            )}
          ></div>
        </div>
      ),
      onClick: () => {
        createNewProjectService.addNewProject();
      },
    },
    {
      hide: hidenCurrentProject.toString(),
      key: "currentProject",
      label: (
        <CurrentProject
          ref={currentProjectRef}
          homeNavBarProps={props}
          clickItemState={{ clickItemButton, setClickItemButton }}
          outerState={{
            saveLoading: homeStoreState.homeNavSaveLoading,
            selectedTemplate,
            createJSFuncData,
            createAPIFuncData,
            isFunc,
            fetching,
          }}
          disabled={fetching}
          onSave={handleProjectTitleSave}
        />
      ),
      onClick: () => {
        clickCurrentProject();
      },
      disabled: fetching || !selectedTemplate.name,
    },
    //to be added back in later when multiple tabs supported
    // {
    //   key: 'addProjects',
    //   label: <AddProjects />,
    //   onClick: () => {
    //     setIsNewProjectModalOpen(true);
    //     setNewFunctionData({ name: '', description: '' });
    //   },
    //   disabled: fetching
    // },
    {
      key: "save",
      label: showNavPageState.showNavPage && !isFunc ? null : <SaveProjects />,
      disabled: (fetching || (!selectedTemplate.name && !myModificationDataState.showMyModification)) && !isFunc,
    },
    {
      key: "runAll",
      label:
        showNavPageState.showNavPage || myModificationDataState.showMyModification || isFunc ? null : (
          <RunAllTop onMouseDown={() => edgeRunAll.clickRunAllBtn()} />
        ),
      disabled: fetching || !selectedTemplate.name,
    },
    {
      key: "run",
      label: !isFunc && !myModificationDataState.showMyModification ? null : <RunButton />,
      onClick: () => {
        RunFunction();
      },
    },
  ];
  /// 从右侧排布的数据
  const rightItems = [
    {
      key: "user",
      label: (
        <UserAvatarV2
          dropdownItems={[
            {
              key: "myAccount",
              label: "My Account",
              icon: getAssetsIcon("myaccount_enter.svg"),
              closed: true,
            },
            {
              key: "logout",
              label: "Log out",
              icon: getAssetsIcon("logout.svg"),
              closed: true,
            },
          ]}
          onDropdownItemClick={handleDropdownItemClick}
        />
      ),
      // className: cls(style.menuItemProfile),
      children: undefined,
    },
    {
      key: "PublishV2",
      label: showNavPageState.showNavPage && !isFunc ? null : <PublishV2Item beforePublish={() => beforePublish()} />,
      "data-testid": "publish-button-v2",
      disabled: (fetching || !selectedTemplate.name) && !isFunc,
    },
    // 禁用老版本Publish按钮
    // {
    //   key: "Publish",
    //   label: showNavPageState.showNavPage && !isFunc ? null : <Publish />,
    //   "data-testid": "publish-button",
    //   disabled: (fetching || !selectedTemplate.name) && !isFunc,
    // },
    {
      label: <DividerItem />,
    },
    {
      key: "copyLink",
      label: showNavPageState.showNavPage && !isFunc ? null : <CopyLinkItem />,
      "data-testid": "copyLink",
    },
    {
      key: "Share",
      label: showNavPageState.showNavPage || myModificationDataState.showMyModification || isFunc ? null : <Share />,
      disabled: fetching || !selectedTemplate.name,
    },
    {
      key: "feedback",
      // className: feedbackClassName,
      label: <Feedback onMenuItemClick={handleFeedbackMenuItemClick} />,
    },
    {
      key: "Upgrade",
      // className: upgradeStyle.bar_item,
      label: featureTags.enableStudiosPaywall ? <UpgradeButton /> : null,
    },
  ];

  const generateShareLink = async () => {
    if (homeStoreState.homeNavSaveLoading) return;
    try {
      navBarStore.setShareLoading(true);
      const data = await fotSaveService.handleSave();
      const url = `${window.location.origin}${window.location.pathname}#/editor`;
      const encodeProjectName = encodeURIComponent(selectedTemplate.name);
      const link = `${url}/shared_canvas/?projectId=${
        data?.updated_project?.id || selectedTemplate.id
      }&projectName=${encodeProjectName}&author=${userMe.id}`;
      navigator.clipboard.writeText(link);

      // showShareProjectTip();
      func.customMsg({
        content: "Link copied to clipboard",
        type: "info",
      });

      navBarStore.setShareLoading(false);
      logEvent("share_project", { URL: link });
    } catch (error) {
      console.error(error);
    }
  };

  const hasOptionInLists = value => {
    const list = store.getState().fot.imageGenSelectOptions;
    return list.findIndex(x => x.value === value);
  };

  const handlePublishFunctionNameChange = e => {
    setPublishFunctionName(e.target.value);
  };

  const handlePublishFunctionModalCancel = () => {
    setPublishFunctionName(selectedTemplate.template_name);
    setShowPublishFunctionConfirm(false);
  };

  const handlePublishFunctionConfirm = async () => {
    setPublishFunctionLoading(true);
    try {
      const functionData = createAPIFuncData.open
        ? createAPIFuncData.select
        : createJSFuncData.open
        ? createJSFuncData.select
        : undefined;
      await props.publishFunction(publishFunctionName, newFunctionData?.description, functionData);
      setPublishFunctionLoading(false);
      setShowPublishFunctionConfirm(false);
    } catch (error) {
      setPublishFunctionLoading(false);
      func.messageError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    const idx = hasOptionInLists("midjourney");
    const tempList = JSON.parse(JSON.stringify(store.getState().fot.imageGenSelectOptions));

    if (!showStudiosDevMode) {
      setDeveloperMode(false);
      getIt(UiFunctionService).dirty();
      // 删除midjourney
      if (idx !== -1) {
        tempList.splice(idx, 1);
        setImageGenSelectOptions(tempList);
      }
    } else {
      if (!developerMode) return;
      // 添加midjourney
      if (idx === -1) {
        const imageGenIdx = hasOptionInLists("imageGen");
        tempList.splice(imageGenIdx, 0, getMidjourney);
        setImageGenSelectOptions(tempList);
      }
    }
  }, [userMe?.feature_tags]);

  useEffect(() => {
    const imageGenSelectOptions = [...store.getState().fot.imageGenSelectOptions];
    let disableExperimental = !enableExperimentalFeature;
    // 如果权限被屏蔽直接不显示
    if (!showExperimentalFeaturesToggle) {
      disableExperimental = true;
    }

    // if ( showExperimentalFeaturesToggle === true && enableExperimentalFeature === true ) {
    //   disableExperimental = false;
    // }

    // if( showExperimentalFeaturesToggle === false  ) {
    //   disableExperimental = true;
    // }
    const videoIndex = imageGenSelectOptions.findIndex(item => item.label === "Video");
    const jdShoppingIndex = imageGenSelectOptions.findIndex(item => item.label === "Jd Shopping");
    const amazonShoppingIndex = imageGenSelectOptions.findIndex(item => item.label === "Amazon Shopping(Old)");
    const weeeShoppingIndex = imageGenSelectOptions.findIndex(item => item.label === "Weee Shopping(Old)");

    if (videoIndex !== -1) {
      const newVideo = { ...imageGenSelectOptions[videoIndex], hide: disableExperimental };
      imageGenSelectOptions[videoIndex] = newVideo;
    }

    if (jdShoppingIndex !== -1) {
      const newJdShopping = { ...imageGenSelectOptions[jdShoppingIndex], hide: disableExperimental };
      imageGenSelectOptions[jdShoppingIndex] = newJdShopping;
    }

    if (amazonShoppingIndex !== -1) {
      const newVideo = { ...imageGenSelectOptions[amazonShoppingIndex], hide: disableExperimental };
      imageGenSelectOptions[amazonShoppingIndex] = newVideo;
    }

    if (weeeShoppingIndex !== -1) {
      const newVideo = { ...imageGenSelectOptions[weeeShoppingIndex], hide: disableExperimental };
      imageGenSelectOptions[weeeShoppingIndex] = newVideo;
    }

    setImageGenSelectOptions(imageGenSelectOptions);
  }, [showExperimentalFeaturesToggle, enableExperimentalFeature]);

  useEffect(() => {
    if (createJSFuncData.open) return;
    setIsNewCustomFunctionOpen(false);
  }, [createJSFuncData.open]);
  useEffect(() => {
    if (createAPIFuncData.open) return;
    setIsNewCustomFunctionOpen(false);
  }, [createAPIFuncData.open]);

  useEffect(() => {
    if (mode === "editorMode") return;
    // bsf-4912 use setSassUIData
    creatorSaasAppStore.setSaasUIData(prev => ({ ...prev, output: [] }));
  }, [selectedTemplate.name]);

  useEffect(() => {
    if (saveAsExistingTemplate) {
      handleSaveAsExistingTemplate();
    }
  }, [saveAsExistingTemplate]);

  useEffect(() => {
    if (updateSelectedTemplate) {
      getTemplateByName(selectedTemplate.id, true);
      // adpotSelectedExistingProject();
    }
  }, [updateSelectedTemplate]);

  useEffect(() => {
    if (selectedTemplate?.[dataVersion]?.showToolTipStatus === "show") {
      // 延迟一段时间执行
      setTimeout(() => {
        moveRightNodeToCenter();
      }, 500);
    }
  }, [selectedTemplate?.[dataVersion]?.showToolTipStatus]);

  const menuClassName = cls(style["home-navber-box"], style.menuComponent, style.nav_bar_view, {
    [style.hideBackDrapFilter]: chatOBState.isOpenChat, // backdrop-filter 导致闪烁问题
  });

  const navbarClassName = useMemo(() => {
    return cls(
      style["navber-back"],
      props.page === "home" && !myModificationDataState.showMyModification && style.navComponent
    );
  }, [props.page, showNavPageState.showNavPage, myModificationDataState.showMyModification]);

  return (
    <div id="homeNavBar" className={navbarClassName}>
      <div className={menuClassName}>
        <Menu
          className={style.leftmenu}
          selectable={false}
          mode="horizontal"
          getPopupContainer={e => e}
          key="leftmenu"
          items={items.filter(item => item.hide === "false" || !item.hide)}
          triggerSubMenuAction="click"
        />
        <Menu
          selectable={false}
          className={style.rightmenu}
          mode="horizontal"
          getPopupContainer={e => e}
          key="rightmenu"
          items={rightItems.filter(item => item.hide === "false" || !item.hide)}
          triggerSubMenuAction="click"
        />
      </div>
      <Modal
        title={customFunctionTitle}
        open={isNewCustomFunctionOpen}
        footer={[
          <Button key="cancel" type="Default" onClick={handleFunctionData}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            disabled={func.isEmpty(newFunctionData.name)}
            onClick={handleFunctionDataOk}
            loading={isFuncLoading}
          >
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Name" rules={[{ required: true, message: "Please enter function name" }]}>
            <Input
              autoComplete="off"
              placeholder="Enter function name"
              value={newFunctionData.name}
              onChange={e => newFunctionDataChange(e, "name")}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              autoComplete="off"
              placeholder="Enter function description"
              value={newFunctionData.description}
              onChange={e => newFunctionDataChange(e, "description")}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Publish Global Function"
        open={showPublishFunctionConfirm}
        onOk={handlePublishFunctionConfirm}
        onCancel={handlePublishFunctionModalCancel}
        okButtonProps={{ loading: publishFunctionLoading }}
        style={{ fontFamily: "Ak-Regular" }}
      >
        <p>
          Please confirm the name of your global function. Once you publish this function everyone will be able to see
          and use it.
        </p>
        <Form layout="vertical" validateTrigger="onBlur" autoComplete="off">
          <Form.Item label="Function Name" rules={[{ required: true, message: "Please enter fucntion name" }]}>
            <Input
              placeholder="Please enter function name"
              value={publishFunctionName}
              onChange={handlePublishFunctionNameChange}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              autoComplete="off"
              placeholder="Enter function description"
              value={newFunctionData.description}
              onChange={e => newFunctionDataChange(e, "description")}
            />
          </Form.Item>
        </Form>
      </Modal>

      <BackHomeModal
        backHomeModal={backHomeModal}
        setBackHomeModal={setBackHomeModal}
        saveAndExit={saveAndExit}
        handleReset={handleReset}
      />
    </div>
  );
};
export default HomeNavBar;
